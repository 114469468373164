import React from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {addAlert} from '../../../store/reducers/alertSlice';
import {confirmRegistration} from '../../../store/reducers/authSlice';
import Loader from '../../Loader';
import Translation from '../../Translation';
import {UserRole} from "../../../model/user";
import {AlertType, LoaderType} from "../../../model/common";

export interface IBaseAuthPanelComponentProps {
    readonly isLoading: boolean;
    readonly userRole: UserRole;
}


const ConfirmRegistration: React.FC<IBaseAuthPanelComponentProps> = ({isLoading, userRole}) => {
    const {id} = useParams(),
        dispatch = useDispatch();

    const sendConfirmRegistration = (): void => {
        if (!id) {
            dispatch(addAlert({message: 'alerts.auth.invalidConfirmRegistrationUrl', type: AlertType.WARNING}));
            return;
        }

        dispatch(confirmRegistration(id, userRole));
    };

    return (
        <>
            <Loader showLoader={isLoading} type={LoaderType.Local} />
            <div className="auth-form-header">
                <p className="title">
                    <Translation text={'auth.confirmRegistration.title'} />
                </p>
                <p className="description">
                   <Translation text={'auth.confirmRegistration.description'} />
                </p>
            </div>
            <div className="btn-container">
                <button onClick={() => sendConfirmRegistration()}
                    className="btn-primary">
                    <Translation text="auth.confirmRegistration.buttons.confirm" />
                </button>
            </div>
        </>
    );
};

export default ConfirmRegistration;
