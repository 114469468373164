import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {authTokenSelector} from '../../store/selectors/authSelectors';
import {isNotNullOrUndefined, isNullOrUndefined} from '../../utils/runtimeUtils';
import DefaultAvatar from '../../assets/icons/default-avatar';
import Loader from '../Loader';
import {IAuthorizedImageProps, LoaderType} from '../../model/common';

type Props = IAuthorizedImageProps;

const AuthorizedImage: React.FC<Props> = (props) => {
    const authToken = useSelector(authTokenSelector);
    const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(false);
    const [image, setImage] = useState<string | null>();

    useEffect(() => {
        if ((isNullOrUndefined(props.url) && isNullOrUndefined(props.id)) || isNullOrUndefined(authToken)) {
            return;
        }
        const url = isNotNullOrUndefined(props.url) ? props.url : `/api/media_objects/${props.id}/stream`;
        const loadImage = async () => {
            setIsAvatarLoading(true);
            try {
                const response = await window.fetch(url as string, {
                        method: 'GET',
                        headers: new Headers({
                            Accept: 'application/ld+json',
                            'Content-type': 'application/ld+json',
                            Authorization: `Bearer ${authToken as string}`,
                        }),
                    }),
                    blob = await response.blob();
                setImage(URL.createObjectURL(blob));
            } catch (error) {
                console.error(error);
            } finally {
                setIsAvatarLoading(false);
            }
        };

        loadImage();
    }, [props.url, props.id, authToken]);

    let imageSource = image;
    if (isNullOrUndefined(imageSource) && isNotNullOrUndefined(props.default)) {
        imageSource = props.default;
    }

    return isNotNullOrUndefined(imageSource) ? (
        <img className="image" src={imageSource as string} alt={props.alt} />
    ) : (
        <div className="authorized-image default-image-container">
            <Loader showLoader={isAvatarLoading} type={LoaderType.Local} />
            <DefaultAvatar />
        </div>
    );
};

export default AuthorizedImage;
