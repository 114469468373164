import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes} from 'reservation-common-web';

const reservationsSearchFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'search',
            label: t('reservationsView.filters.search'),
            isRequired: false,
            type: FormikFieldTypes.TEXT,
            validation: Yup.string(),
            className: '',
        },
        {
            name: 'roomId',
            label: t('reservationsView.filters.room'),
            isRequired: false,
            type: FormikFieldTypes.TEXT,
            validation: Yup.string(),
            className: '',
        },
        {
            name: 'dateRange',
            label: t('reservationsView.filters.date'),
            isRequired: false,
            type: FormikFieldTypes.DATERANGE,
            validation: Yup.string(),
            className: '',
        },
    ];
};

export default reservationsSearchFormConfig;
