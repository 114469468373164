import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator, IRestaurantOutput} from 'reservation-common-web';
import CustomCard from '../../../shared/CustomCard';
import restaurantDetailsCardFormConfig from './restaurantDetailsCardFormConfig';
import {isRestaurantDetailsValidSelector, restaurantSelector} from '../../../../store/selectors/restaurantSelector';
import {ISetRestaurantDetails, setRestaurantDetails, setIsRestaurantDetailsValid} from '../../../../store/reducers/restaurantSlice';

const RestaurantDetailsCard: React.FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const restaurant: IRestaurantOutput | null = useSelector(restaurantSelector);
    const isRestaurantDetailsValid: boolean = useSelector(isRestaurantDetailsValidSelector);

    const toggleValidity = (valid: boolean) => (valid !== isRestaurantDetailsValid ? dispatch(setIsRestaurantDetailsValid(valid)) : null);
    const handleSubmit = (values: any) => {
        const payload: ISetRestaurantDetails = {
            name: values.name,
            address: values.address,
            email: values.email,
            phone: values.phone,
            privacyPolicy: values.privacyPolicy,
        };

        dispatch(setRestaurantDetails(payload));
    };

    const initialValues = {
        name: restaurant?.name || '',
        address: restaurant?.address || '',
        email: restaurant?.email || '',
        phone: restaurant?.phone || '',
        privacyPolicy: restaurant?.configuration?.privacyPolicy || '',
    };

    const renderForm = () => {
        if (!restaurant) return;
        return (
            <FormGenerator
                config={{
                    fields: restaurantDetailsCardFormConfig(t),
                    initialValues,
                    onSubmit: handleSubmit,
                    debouncedChangeTimeout: 1000,
                    formId: 'change-room-details-form',
                    formClassName: '',
                }}
                isFormValid={toggleValidity}
            />
        );
    };

    return <CustomCard title="restaurantForm.restaurantDetailsCard.title" content={renderForm()} />;
};

export default RestaurantDetailsCard;
