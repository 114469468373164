import {push} from 'react-router-redux';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
    AlertType,
    DetailsSuccessActionsFunction,
    IRestaurantOutput,
    addAlert,
    closeModal,
    createRestaurantAPI,
    createOperationEpic,
    deleteRestaurantAPI,
    handleApiError,
    updateRestaurantAPI,
    authTokenSelector,
    getRestaurantAPI,
    accountRestaurantSelector,
    setAccountRestaurant,
} from 'reservation-common-web';
import {
    initRestaurant,
    setRestaurant,
    createRestaurant,
    deleteRestaurant,
    setError,
    setLoading,
    updateRestaurant,
    setInitialized,
} from '../reducers/restaurantSlice';
import {
    fromIRestaurantOutputToIRestaurantInput,
    fromRestaurantResponseToIRestaurantOutput,
    getEmptyRestaurantOutput,
} from '../../utils/restaurantUtils';

const errorActions = (error: any): any[] => {
    const errorObj = handleApiError(error);
    errorObj.type = AlertType.WARNING;
    return [setLoading(false), setError(errorObj.message), addAlert(errorObj)];
};

const initRestaurantSuccessActions: DetailsSuccessActionsFunction<IRestaurantOutput> = (restaurant: IRestaurantOutput) => {
    return [
        // addAlert({message: 'alerts.restaurantForm.retrieved'}),
        setLoading(false),
        setInitialized(true),
        setRestaurant(fromRestaurantResponseToIRestaurantOutput(restaurant)),
    ];
};

const initRestaurantEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(initRestaurant().type),
        switchMap(() => {
            const accountRestaurant: IRestaurantOutput | null = accountRestaurantSelector(state$.value);

            if (!accountRestaurant) {
                const actions = [setLoading(false)];

                if (!state$.value.restaurant.isInitialized) actions.push(setRestaurant(getEmptyRestaurantOutput()), setInitialized(true));

                return of(...actions);
            }

            const authToken = authTokenSelector(state$.value),
                slug = accountRestaurant.slug;

            return getRestaurantAPI(authToken, {slug}).pipe(
                mergeMap((res: IRestaurantOutput) => of(...initRestaurantSuccessActions(res))),
                catchError(errorActions)
            );
        })
    );

const createRestaurantSuccessActions: DetailsSuccessActionsFunction<IRestaurantOutput> = (restaurant: IRestaurantOutput) => {
    return [addAlert({message: 'alerts.restaurantForm.created'}), setLoading(false), setAccountRestaurant(restaurant)];
};

const createRestaurantEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(createRestaurant().type),
        switchMap(() => {
            const restaurant: IRestaurantOutput | null = state$.value.restaurant.restaurant;

            if (!restaurant) return of(setLoading(false));

            const authToken = authTokenSelector(state$.value);
            return createRestaurantAPI(authToken, fromIRestaurantOutputToIRestaurantInput(restaurant)).pipe(
                mergeMap((res: IRestaurantOutput) => of(...createRestaurantSuccessActions(res))),
                catchError((error) => {
                    return errorActions(error);
                })
            );
        })
    );

const updateRestaurantSuccessActions: DetailsSuccessActionsFunction<IRestaurantOutput> = (restaurant: IRestaurantOutput) => {
    return [addAlert({message: 'alerts.restaurantForm.updated'}), setLoading(false)];
};

const updateRestaurantEpic: Epic = (action$, state$) =>
    action$.pipe(
        ofType(updateRestaurant().type),
        switchMap(() => {
            const restaurant: IRestaurantOutput | null = state$.value.restaurant.restaurant;

            if (!restaurant) return of(setLoading(false));

            const authToken = authTokenSelector(state$.value);
            return updateRestaurantAPI(authToken, {
                id: restaurant.id,
                restaurant: fromIRestaurantOutputToIRestaurantInput(restaurant),
            }).pipe(
                mergeMap((res: IRestaurantOutput) => of(...updateRestaurantSuccessActions(res))),
                catchError((error) => {
                    return errorActions(error);
                })
            );
        })
    );

const deleteRestaurantSuccessActions: DetailsSuccessActionsFunction<null> = () => {
    return [addAlert({message: 'alerts.restaurantForm.deleted'}), setLoading(false), closeModal(), push('/panel/')];
};

const deleteRestaurantEpic = createOperationEpic<null>(
    deleteRestaurantAPI,
    deleteRestaurantSuccessActions,
    errorActions,
    deleteRestaurant().type
);

const restaurantEpic = combineEpics(initRestaurantEpic, createRestaurantEpic, updateRestaurantEpic, deleteRestaurantEpic);

export default restaurantEpic;
