import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import pl from './assets/i18n/pl';
import 'moment/locale/pl';
import en from './assets/i18n/en';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    pl: {
        translation: pl,
    },
    en: {
        translation: en,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'pl',

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });
i18n.changeLanguage('pl');
export default i18n;
