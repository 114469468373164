import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {reservationsAPI} from '../provider/reservationsAPI';
import {IApiSingleResponseBase, IRawRestQueryParams} from '../../model/base';
import {IRestaurantOutput} from '../../model/restaurant';

export function getRestaurantAPI(
    authToken: string,
    payload?: {slug: string},
    params?: IRawRestQueryParams | []
): Observable<IApiSingleResponseBase<IRestaurantOutput>> {
    return reservationsAPI.get(`api/restaurants/${payload.slug}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
