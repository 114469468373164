import {AnyAction, createSlice, PayloadAction, Reducer, SliceCaseReducers} from '@reduxjs/toolkit';

export type IBaseReducerState = {
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
};

interface IBaseReducerPayload {
    isLoading?: boolean;
    isInitialized?: boolean;
    error?: string | null;
}

export interface IBaseDictionaryState {
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
}

export interface IChangeIsLoading {
    isLoading: boolean;
}

export interface IChangeIsInitialized {
    isInitialized: boolean;
}

export interface ISetError {
    error: string | null;
}

type ReduxAction<T extends string, P = void> = P extends void ? {type: T} : {type: T; payload: P};

interface BaseReducerSlice<S, A extends AnyAction = AnyAction> {
    reducer: Reducer<S, A>;
    actions: {
        [key: string]: (...args: any[]) => ReduxAction<string, any>;
    };
}

const createBaseReducerSlice = <State extends object>(slice: {
    name: string;
    initialState: {[key: string]: any};
    reducers?: SliceCaseReducers<State>;
}): BaseReducerSlice<any> => {
    const {name, initialState, reducers} = slice;

    const baseReducerSlice = createSlice({
        name,
        initialState,
        reducers: {
            setLoading: {
                reducer: (state, action: PayloadAction<IBaseReducerPayload>) => {
                    return {
                        ...state,
                        isLoading: action.payload?.isLoading ?? false,
                    };
                },
                prepare(isLoading: boolean) {
                    return {
                        payload: {isLoading},
                    };
                },
            },
            setInitialized: {
                reducer: (state, action: PayloadAction<IBaseReducerPayload>) => {
                    return {
                        ...state,
                        isInitialized: action.payload?.isInitialized ?? false,
                    };
                },
                prepare(isInitialized: boolean) {
                    return {
                        payload: {isInitialized},
                    };
                },
            },
            setError: {
                reducer: (state, action: PayloadAction<IBaseReducerPayload>) => {
                    return {
                        ...state,
                        error: action.payload?.error ?? null,
                    };
                },
                prepare(error: string | null) {
                    return {
                        payload: {error},
                    };
                },
            },
            ...(reducers || {}),
        },
    });

    return baseReducerSlice;
};

export default createBaseReducerSlice;
