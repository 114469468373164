import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes, FormikCustomValidators} from 'reservation-common-web';

const restaurantDetailsCardFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'name',
            label: t('restaurantForm.restaurantDetailsCard.form.labels.name'),
            isRequired: true,
            type: FormikFieldTypes.TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'address',
            label: t('restaurantForm.restaurantDetailsCard.form.labels.address'),
            isRequired: true,
            type: FormikFieldTypes.TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'email',
            label: t('restaurantForm.restaurantDetailsCard.form.labels.email'),
            isRequired: true,
            type: FormikFieldTypes.EMAIL,
            validation: Yup.string().matches(FormikCustomValidators.EMAIL, t('validation.invalidEmail')).required(t('validation.required')),
            className: '',
        },
        {
            name: 'phone',
            label: t('restaurantForm.restaurantDetailsCard.form.labels.phone'),
            isRequired: true,
            type: FormikFieldTypes.TEXT,
            validation: Yup.string().matches(FormikCustomValidators.PHONE, t('validation.invalidPhone')).required(t('validation.required')),
            className: '',
        },
        {
            name: 'privacyPolicy',
            label: t('restaurantForm.restaurantDetailsCard.form.labels.privacyPolicy'),
            type: FormikFieldTypes.RICH_TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
    ];
};

export default restaurantDetailsCardFormConfig;
