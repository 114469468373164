export const phoneCodesList = [
    //Chwilowo nie uzywamy krajow z poza Europy

    {label: 'GR', value: '+30'},
    {label: 'NL', value: '+31'},
    {label: 'BE', value: '+32'},
    {label: 'FR', value: '+33'},
    {label: 'ES', value: '+34'},
    {label: 'HU', value: '+36'},
    {label: 'IT', value: '+39'},
    {label: 'RO', value: '+40'},
    {label: 'CH', value: '+41'},
    {label: 'AT', value: '+43'},
    {label: 'GB', value: '+44'},
    {label: 'DK', value: '+45'},
    {label: 'SE', value: '+46'},
    {label: 'NO', value: '+47'},
    {label: 'PL', value: '+48'},
    {label: 'DE', value: '+49'},
    {label: 'GI', value: '+350'},
    {label: 'PT', value: '+351'},
    {label: 'LU', value: '+352'},
    {label: 'IE', value: '+353'},
    {label: 'IS', value: '+354'},
    {label: 'AL', value: '+355'},
    {label: 'MT', value: '+356'},
    {label: 'CY', value: '+357'},
    {label: 'FI', value: '+358'},
    {label: 'BG', value: '+359'},
    {label: 'LT', value: '+370'},
    {label: 'LV', value: '+371'},
    {label: 'EE', value: '+372'},
    {label: 'MD', value: '+373'},
    {label: 'BY', value: '+375'},
    {label: 'MC', value: '+377'},
    {label: 'VA', value: '+379'},
    {label: 'UA', value: '+380'},
    {label: 'RS', value: '+381'},
    {label: 'ME', value: '+382'},
    {label: 'XK', value: '+383'},
    {label: 'HR', value: '+385'},
    {label: 'SI', value: '+386'},
    {label: 'BA', value: '+387'},
    {label: 'MK', value: '+389'},
    {label: 'CZ', value: '+420'},
    {label: 'SK', value: '+421'},
    {label: 'LI', value: '+423'},
    {label: 'MV', value: '+960'},
];
