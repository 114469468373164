import * as Yup from 'yup';
import { FormikFieldConfig, FormikFieldTypes} from '../../../model/form';

const registrationFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'plan',
            label: t('auth.register.form.labels.plan'),
            type: FormikFieldTypes.SELECT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
            options: [
                {label: t('auth.register.form.planOptions.free'), value: 'employee'},
            ],
        },
        {
            name: 'email',
            label: t('auth.register.form.labels.email'),
            type: FormikFieldTypes.EMAIL,
            validation: Yup.string().email(t('validation.invalidEmail')).required(t('validation.required')),
            className: '',
        },
        {
            name: 'password',
            label: t('auth.register.form.labels.password'),
            type: FormikFieldTypes.PASSWORD,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        }, 
        {
            name: 'repeatPassword',
            label: t('auth.register.form.labels.repeatPassword'),
            type: FormikFieldTypes.PASSWORD,
            validation: Yup.string().required(t('validation.required')).oneOf([Yup.ref('password')], t('validation.passwordsMustMatch')),
            className: '',
        },
        {
            name: 'privacyPolicy',
            label: t('auth.register.form.labels.privacyPolicy'),
            type: FormikFieldTypes.CHECKBOX,
            validation: Yup.bool().oneOf([true], t('validation.mustAcceptPrivacyPolicy')).required(t('validation.required')),
            className: '',
        },
    ];
};

export default registrationFormConfig;
