import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IReservationsState} from '../reducers/reservationsSlice';

export const selectReservationsSlice = (state: RootState) => {
    return state.reservations;
};

export const reservationsSelector = createSelector([selectReservationsSlice], (state: IReservationsState) => state.reservations);
export const isReservationsLoadingSelector = createSelector([selectReservationsSlice], (state: IReservationsState) => state.isLoading);
export const reservationsPaginationSelector = createSelector([selectReservationsSlice], (state: IReservationsState) => state.pagination);

export const reservationsMetadataSelector = createSelector([selectReservationsSlice], (state: IReservationsState) => state.metadata);

export const reservationsFiltersSelector = createSelector([selectReservationsSlice], (state: IReservationsState) => state.filters);

export const reservationsInitializedSelector = createSelector(
    [selectReservationsSlice],
    (state: IReservationsState) => state.isInitialized
);
export const reservationsErrorSelector = createSelector([selectReservationsSlice], (state: IReservationsState) => state.error);
