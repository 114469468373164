import React from 'react';

const LogoComponent = () => (
    <img
        className="logo"
        alt="Application logo"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJnSURBVHgBpVW9bhNBEJ7ZPdvE2NKBXPBTYJDojeSOIkeD6exnCLHyBNSEipYGJISQBS8QEAUFCiYSlYklOtIhikRAjJBin2Xnbncyd4nPzvn8d/6K273Zve+b3fk5hCWRr9XNjusWBGrz73rpXXgdIQZyrz5ZPBQAqcwUBQAy/QWiJ61qaXN0rzEPoVn7mDdco8LurPKrFRCesg5nAm+EvzWmeYlCex5WSEGevYVZEAA7MwWu1Op5Vzk19sciWvAGFXz3hk0i8aHZlM1i0cEwudJOnYg9XhSI+z107hg6lZak057JQfXn3AmY/HEscg8E31I6kQPQgenCsTTFuT0EFYgJ0vpz2KZT1AsEzlLPhLiQ4kfYpNzL9sgJtAVxQbDHN7MfNv8H6A4FEFchJlBgI2yTDvRho+j4AiaXO/gFFBMUff/e6AtIpSxYAppEY9yabgcC3KjKEBcIjShza+2uHQhwehYgJkjD+7AtiaLLMfULQnjVCxBPABGe83NrTFT0u4O5wdVrwaJAOCKgF6TF26hl+ieHAtzQyqMtdxopgtgGrXe1om0Q4ihyG3EDelSyAwEmz08i5Ocee8opyHmu0Zv7C5wVMBFSd0ZfDY7SG66UQQy+cMR3uEv/BD1IvflbtkDDOVyzfsPDEVur+uCZK9XNjLQvtdbv3zOv33oKkXk9HV7mHKrsr0H2DDDmnlnbMqWbuQpzeawcJKNtaLN9sFHsRu0Z+6MZ+mJ6UshRSJ3sOd1OKmvfVsl2k3sNzMCYQHIlcdy33YAwQdSzYaWdUcneQXXoZROWQO711+y1l7tpDriAJXEClmv17tZ1hfoAAAAASUVORK5CYII="
    />
);

export default LogoComponent;