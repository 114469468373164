import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Translation from '../Translation';
import styles from './styles.module.scss';
import {AlertObject, removeAlert} from '../../store/reducers/alertSlice';
import {alertsSelector, disabledAlertsSelector} from '../../store/selectors/alertSelectors';

const ToastComponent: React.FC = () => {
    const alerts: AlertObject[] = useSelector(alertsSelector),
        alertsDisabled = useSelector(disabledAlertsSelector),
        dispatch = useDispatch(),
        timeoutIdsRef = useRef<ReturnType<typeof setTimeout>[]>([]);

    useEffect(() => {
        return () => {
            timeoutIdsRef.current.forEach(clearTimeout);
        };
    }, []);

    const closeToast = (id: number | undefined) => {
        if (id) {
            dispatch(removeAlert(id));
        }
    };

    const removeAlerts = () => {
        alerts.map((alert: AlertObject) => {
            if (alert.displayFor !== 0) {
                timeoutIdsRef.current.forEach(clearTimeout);
                timeoutIdsRef.current = [];

                const timeoutId = setTimeout(() => {
                    if (alert.id) {
                        dispatch(removeAlert(alert.id));
                    }
                }, alert.displayFor);
                timeoutIdsRef.current.push(timeoutId);
            }

            return alert;
        });
    };

    useEffect(() => {
        removeAlerts();
    }, [alerts]);

    if (alertsDisabled || !alerts.length) {
        return null;
    }

    return (
        <React.Fragment>
            {alerts.map((alert: AlertObject) => {
                const message = !alert.isTranslated ? alert.message : <Translation text={alert.message} />;

                return (
                    <div key={`toast_${alert.id}`} className={`${styles.toast} ${alert.type ? `${styles[alert.type]}` : ''}`}>
                        <div className={styles.toastBody}>
                            <div className={styles.toastMessageWrapper}>
                                {alert.type && (
                                    <p className={styles.toastHeading}>
                                        <Translation text={`alerts.${alert.type}`} />
                                    </p>
                                )}
                                <span className={styles.toastMessage}>{message}</span>
                            </div>
                        </div>
                        <button type="button" className={styles.btnClose} onClick={() => closeToast(alert.id)} />
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default ToastComponent;
