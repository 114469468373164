import React from 'react';
import Translation from '../../Translation';
import {UserRole} from "../../../model/user";
import LogoComponent from "./LogoComponent";

export interface IAuthHeaderProps {
    userRole: UserRole;
}

const AuthHeader: React.FC<IAuthHeaderProps> = ({userRole}) => {
    const getLogo = () => {
        switch (userRole) {
            case UserRole.EMPLOYEE:
                return <LogoComponent />;

            case UserRole.USER:
                return <LogoComponent />;

            default:
                return null;
        }
    };

    return (
        <div className="auth-header">
            {getLogo()}

            <h2 className="auth-header-title">
                <Translation text={`app.title`} />
            </h2>
        </div>
    );
};

export default AuthHeader;
