import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface IChangePasswordState {
    error: string | null;
    isPasswordChanged: boolean;
    isLoading: boolean;
}

export interface ISetChangePasswordError {
    readonly error: string;
}

export interface IChangePasswordLoading {
    readonly isLoading: boolean;
}

export interface ISetIsPasswordChanged {
    readonly isPasswordChanged: boolean;
}

export interface IChangePasswordStart {
    readonly oldPassword: string;
    readonly newPassword: string;
}

const initialState: IChangePasswordState = {
    error: null,
    isPasswordChanged: false,
    isLoading: false,
};

const changePasswordSlice = createSlice({
    name: 'changePassword',
    initialState: initialState,
    reducers: {
        changePasswordStart: {
            reducer: (state: IChangePasswordState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(oldPassword: string, newPassword: string) {
                return {
                    payload: {
                        oldPassword: oldPassword,
                        newPassword: newPassword,
                    },
                };
            },
        },
        setChangePasswordFailure: {
            reducer: (state: IChangePasswordState, action: PayloadAction<ISetChangePasswordError>) => {
                return {
                    error: action.payload.error,
                    isPasswordChanged: state.isPasswordChanged,
                    isLoading: false,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        changePasswordLoading: {
            reducer: (state: IChangePasswordState, action: PayloadAction<IChangePasswordLoading>) => {
                return {
                    ...state,
                    isLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {
                        isLoading: isLoading,
                    },
                };
            },
        },
        changeIsPasswordChanged: {
            reducer: (state: IChangePasswordState, action: PayloadAction<ISetIsPasswordChanged>) => {
                return {
                    error: state.error,
                    isPasswordChanged: action.payload.isPasswordChanged,
                    isLoading: false,
                };
            },
            prepare(isPasswordChanged: boolean) {
                return {
                    payload: {
                        isPasswordChanged: isPasswordChanged,
                    },
                };
            },
        },
    },
});

export const {changePasswordStart, setChangePasswordFailure, changeIsPasswordChanged, changePasswordLoading} = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
