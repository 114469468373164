import axios from 'axios';
import {saveAs} from 'file-saver';
import {AnyAction} from 'redux';
import {addAlert} from '../store/reducers/alertSlice';
import {handleApiError} from './errorHandlingUtils';

export const downloadInvoice = async (
    invoiceId: string,
    apiString: string,
    authToken: string,
    dispatch: (action: AnyAction) => void,
    invoiceName?: string
) => {
    try {
        const response = await axios.get(`${apiString}/api/invoices/${invoiceId}/stream`, {
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/ld+json',
                Authorization: `Bearer ${authToken}`,
                'Content-type': 'application/pdf',
            },
        });
        const invoiceNameFromHeaders = response.headers?.['content-disposition'].split('filename=').pop();
        const blob = new Blob([response.data], {
            type: 'application/pdf',
        });
        saveAs(blob, invoiceNameFromHeaders ? invoiceNameFromHeaders : invoiceName ? invoiceName : invoiceId);
    } catch (error: any) {
        dispatch(addAlert(handleApiError(error)));
    }
};
