import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LoaderType } from "../../../model/common";
import { UserRole } from "../../../model/user";
import { getLoginCredentials } from '../../../store/reducers/loginSlice';
import { isAuthenticatedSelector } from '../../../store/selectors/authSelectors';
import { loginLoadingSelector } from '../../../store/selectors/loginSelectors';
import FormGenerator from '../../FormGenerator';
import Loader from '../../Loader';
import Translation from '../../Translation';
import loginFormConfig from './loginFormConfig';

export interface ILoginProps {
    readonly userRole: UserRole;
}

const Login: React.FC<ILoginProps> = ({userRole}) => {
    const isAuthenticated = useSelector((state) => isAuthenticatedSelector(state)),
        isLoading = useSelector(loginLoadingSelector),
        dispatch = useDispatch(),
        navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated]);
    type InitialValuesType = {
        [key: string]: any;
    };
    const {t} = useTranslation();
    const formConfig = loginFormConfig(t);
    const initialValues: InitialValuesType = formConfig.reduce((acc, field) => {
        acc[field.name] = '';
        return acc;
    }, {} as InitialValuesType);
    const login = (values: any): any => {
        return dispatch(getLoginCredentials(values.email, values.password, userRole));
    };
    
    const registerUrl = '/auth/register',
        resetPasswordUrl =  '/auth/reset-password';

    return (
        <>
            <Loader showLoader={isLoading} type={LoaderType.Local} />
            <div className="auth-form-header">
                <p className="title"><Translation text={'auth.login.title'} /></p>
                <p className="description">
                    <Trans
                        i18nKey="auth.login.description"
                        components={[<Link to={registerUrl} className="link-text" key="login-link" />]}
                    />
                </p>
            </div>
            <div>
            <FormGenerator
                    config={{
                        fields: formConfig,
                        initialValues,
                        onSubmit: login,
                        formId: 'auth-login-form',
                        formClassName: 'auth-form',
                        submitButtonLabel: 'auth.login.buttons.login',
                        submitButtonClasses: 'btn-primary'
               }}
                />
                <div className="link-container">
                    <Link to={resetPasswordUrl}>
                        <Translation text="auth.login.buttons.resetPassword" />
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Login;
