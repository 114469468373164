import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {IReservationOutput} from '../../model/restaurant';
import {reservationsAPI} from '../provider/reservationsAPI';
import {IApiSingleResponseBase} from '../../model/base';

export function getReservationAPI(
    authToken: string,
    payload?: {restaurantId: string; restaurantToken: string}
): Observable<IApiSingleResponseBase<IReservationOutput>> {
    return reservationsAPI.get(`api/reservations/${payload.restaurantId}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
        'X-Authentication-Token': payload.restaurantToken,
    });
}
