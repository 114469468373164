import React from 'react';

const RocketIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1048_1884)">
            <path
                d="M7.99984 9.99996L5.99984 7.99996M7.99984 9.99996C8.93107 9.64579 9.82443 9.19911 10.6665 8.66663M7.99984 9.99996V13.3333C7.99984 13.3333 10.0198 12.9666 10.6665 12C11.3865 10.92 10.6665 8.66663 10.6665 8.66663M5.99984 7.99996C6.3546 7.07958 6.80131 6.19734 7.33317 5.36663C8.10996 4.12462 9.19159 3.10199 10.4752 2.39602C11.7588 1.69005 13.2016 1.32421 14.6665 1.3333C14.6665 3.14663 14.1465 6.33329 10.6665 8.66663M5.99984 7.99996H2.6665C2.6665 7.99996 3.03317 5.97996 3.99984 5.33329C5.07984 4.61329 7.33317 5.33329 7.33317 5.33329M2.99984 11C1.99984 11.84 1.6665 14.3333 1.6665 14.3333C1.6665 14.3333 4.15984 14 4.99984 13C5.47317 12.44 5.4665 11.58 4.93984 11.06C4.68071 10.8126 4.33937 10.6697 3.98132 10.6586C3.62328 10.6476 3.27375 10.7691 2.99984 11Z"
                stroke="white"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1048_1884">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export default RocketIcon;
