import {FormikFieldConfig, FormikFieldTypes, ImageType} from 'reservation-common-web';
import * as Yup from 'yup';

const formHeaderCardFormConfig = (t: any): FormikFieldConfig[] => {
    return [
        {
            name: 'name',
            label: t('restaurantForm.formHeaderCard.form.labels.name'),
            type: FormikFieldTypes.TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
            isRequired: true,
        },
        {
            name: 'shortDescription',
            label: t('restaurantForm.formHeaderCard.form.labels.shortDescription'),
            type: FormikFieldTypes.RICH_TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'avatar',
            label: t('restaurantForm.formHeaderCard.form.labels.avatar'),
            type: FormikFieldTypes.FILE,
            validation: null,
            className: '',
            fileUploadRequirements: {
                extensions: [ImageType.JPG, ImageType.JPEG, ImageType.PNG],
                size: 1,
                height: 100,
                width: 100,
            },
        },

        {
            name: 'initialDescription',
            label: t('restaurantForm.formHeaderCard.form.labels.initialDescription'),
            type: FormikFieldTypes.RICH_TEXT,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
    ];
};

export default formHeaderCardFormConfig;
