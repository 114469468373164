import {ErrorMessage, Field, useField} from 'formik';
import {CustomFieldProps} from '../../../model/form';
import React from 'react';

const CheckboxField: React.FC<CustomFieldProps> = ({name, label, disabled, placeholder}) => {
    const [field, meta] = useField(name);

    return (
        <div className={`form-control checkbox ${meta.touched && meta.error ? 'invalid' : ''}`} key={name}>
            <label htmlFor={name} className="checkbox-label">
                <Field
                    type="checkbox"
                    id={name}
                    name={name}
                    className="checkbox-input"
                    placeholder={placeholder ? placeholder : ''}
                    disabled={disabled}
                />
                <div className="checkbox-custom" />
                <span className="checkbox-label-value" dangerouslySetInnerHTML={{__html: label}} />
            </label>
            <ErrorMessage name={name} component="div" className="error-message" />
        </div>
    );
};

export default CheckboxField;
