import {
    IRestaurantOutput,
    IRestaurantInput,
    IRestaurantConfigurationInput,
    IRestaurantConfigurationOutput,
    IOpenHoursOutput,
    IFileOutput,
    deepCloneObject,
} from 'reservation-common-web';

export const fromRestaurantResponseToIRestaurantOutput = (restaurantObj?: any): IRestaurantOutput | null => {
    if (!restaurantObj || !restaurantObj.configuration) return null;

    let restaurant: any = deepCloneObject(restaurantObj);

    delete restaurant['@id'];
    delete restaurant['@type'];
    delete restaurant['@context'];

    delete restaurant.configuration['@id'];
    delete restaurant.configuration['@type'];

    delete restaurant.configuration.avatar['@id'];
    delete restaurant.configuration.avatar['@type'];

    delete restaurant.configuration.background['@id'];
    delete restaurant.configuration.background['@type'];

    delete restaurant.configuration.openHours['@id'];
    delete restaurant.configuration.openHours['@type'];

    Object.keys(restaurant.configuration.openHours).forEach((key: string) => {
        if (!restaurant.configuration.openHours[key]) return;

        delete restaurant.configuration.openHours[key]['@id'];
        delete restaurant.configuration.openHours[key]['@type'];
    });

    restaurant.rooms.forEach((room: any) => {
        delete room['@id'];
        delete room['@type'];
    });

    return restaurant;
};

export const fromIRestaurantOutputToIRestaurantInput = (restaurant?: IRestaurantOutput | null): IRestaurantInput | null => {
    if (!restaurant || !restaurant.configuration) return null;

    let rooms: any = deepCloneObject(restaurant.rooms);

    return {
        name: restaurant.name,
        address: restaurant.address,
        email: restaurant.email,
        phone: restaurant.phone,
        configuration: fromIRestaurantConfigurationOutputToIRestaurantConfigurationInput(restaurant.configuration),
        rooms: rooms,
    };
};

export const fromIRestaurantConfigurationOutputToIRestaurantConfigurationInput = (
    configuration: IRestaurantConfigurationOutput
): IRestaurantConfigurationInput => {
    return {
        name: configuration.name,
        privacyPolicy: configuration.privacyPolicy,
        openHours: configuration.openHours,
        color: configuration.color,
        background: configuration.background?.id || '',
        avatar: configuration.avatar?.id || '',
        floorPlan: null,
        shortDescription: configuration.shortDescription,
        initialDescription: configuration.initialDescription,
    };
};

export const getEmptyRestaurantOutput = (): IRestaurantOutput => {
    return {
        id: '',
        name: '',
        address: '',
        email: '',
        phone: '',
        slug: '',
        configuration: getEmptyRestaurantConfigurationOutput(),
        rooms: [],
    };
};

export const getEmptyRestaurantConfigurationOutput = (): IRestaurantConfigurationOutput => {
    return {
        id: '',
        name: '',
        privacyPolicy: '',
        openHours: getEmptyOpenHoursOutput(),
        color: '',
        background: getEmptyFileOutput(),
        avatar: getEmptyFileOutput(),
        shortDescription: '',
        initialDescription: '',
    };
};

export const getEmptyFileOutput = (): IFileOutput => {
    return {
        id: '',
        fileName: null,
        mimeType: null,
        fileUrls: null,
        contentUrl: null,
    };
};

export const getEmptyOpenHoursOutput = (): IOpenHoursOutput => {
    return {
        _0: null,
        _1: null,
        _2: null,
        _3: null,
        _4: null,
        _5: null,
        _6: null,
    };
};
