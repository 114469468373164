import React from 'react';
import {Translation} from 'reservation-common-web';

interface ISubscriptionsProps {}

const Subscriptions: React.FC<ISubscriptionsProps> = () => {
    return (
        <div>
            <div className="header">
                <h2 className="title">
                    <Translation text="subscriptionsView.title" />
                </h2>
            </div>
        </div>
    );
};

export default Subscriptions;
