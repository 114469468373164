import {ErrorMessage, useField, useFormikContext} from 'formik';
import React, {useState} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import 'react-datepicker/dist/react-datepicker.css';
import {CustomFieldProps} from '../../../model/form';
import FormikFieldWrapper from '../../FormikFieldRefWrapper';

const DatePickerField: React.FC<CustomFieldProps> = (
    {name, label, required = false, disabled = false, minDate = null, maxDate = null, excludeDates = [], disabledKeyboardNavigation = true}
) => {
    const [field, meta, helpers] = useField(name);
    const {setFieldValue} = useFormikContext();
    const [fieldFocus, setFieldFocus] = useState(false);

    registerLocale('pl', pl);
    const handleFocus = () => {
        setFieldFocus(true);
    };

    const handleBlur = () => {
        setFieldFocus(false);
    };

    return (
        <div
            className={`form-control datepicker ${meta.touched && meta.error ? 'invalid' : ''} ${
                field.value || fieldFocus ? 'filled' : ''
            }`}
            key={name}>
            <DatePicker
                selected={(field.value && new Date(field.value)) || null}
                id={name}
                name={name}
                showPopperArrow={false}
                popperPlacement="bottom-start"
                onFocus={handleFocus}
                onBlur={handleBlur}
                popperClassName="datepicker-popper"
                placeholderText=""
                onChange={(val: any) => setFieldValue(name, val.toString())}
                dateFormat="dd/MM/yyyy"
                disabled={disabled}
                minDate={minDate as Date}
                maxDate={maxDate as Date}
                excludeDates={excludeDates}
                locale="pl"
                disabledKeyboardNavigation={disabledKeyboardNavigation}
                customInput={
                    <FormikFieldWrapper
                    name={name}
                    placeholder=""
                    className={`form-input datepicker ${field.value || fieldFocus ? 'filled' : ''}`}
                />
                }
            />
            <label htmlFor={name} className="form-label">
                {label} {required && <span className="label-required">*</span>}
            </label>

            <ErrorMessage name={name} component="div" className="error-message" />
        </div>
    );
};

export default DatePickerField;
