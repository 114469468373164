import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes, FormikCustomValidators} from 'reservation-common-web';

const changePasswordFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'oldPassword',
            label: t('accountView.passwordChange.form.labels.oldPassword'),
            type: FormikFieldTypes.PASSWORD,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'newPassword',
            label: t('accountView.passwordChange.form.labels.newPassword'),
            type: FormikFieldTypes.PASSWORD,
            validation: Yup.string()
                .required(t('validation.required'))
                .matches(FormikCustomValidators.PASSWORD, t('validation.passwordPattern')),
            className: '',
        },
        {
            name: 'repeatPassword',
            label: t('accountView.passwordChange.form.labels.repeatPassword'),
            type: FormikFieldTypes.PASSWORD,
            validation: Yup.string()
                .required(t('validation.required'))
                .matches(FormikCustomValidators.PASSWORD, t('validation.passwordPattern'))
                .oneOf([Yup.ref('newPassword')], t('validation.passwordsMustMatch')),
            className: '',
        },
    ];
};

export default changePasswordFormConfig;
