import {createSelector} from '@reduxjs/toolkit';
import {CommonRootState} from '../reducers';
import {ISystemParametersState} from '../reducers/systemParametersSlice';

export const selectSystemParameters = (state: CommonRootState): ISystemParametersState => {
    return state.systemParameters;
};

export const systemParametersSelector = createSelector([selectSystemParameters], (state: ISystemParametersState) => state.parameters);

export const systemParametersLoadingSelector = createSelector([selectSystemParameters], (state: ISystemParametersState) => state.isLoading);

export const systemParametersInitializedSelector = createSelector(
    [selectSystemParameters],
    (state: ISystemParametersState) => state.isInitialized
);

export const systemParametersErrorSelector = createSelector([selectSystemParameters], (state: ISystemParametersState) => state.error);

export const selectSystemParameter = createSelector(
    [systemParametersSelector, (state, paramName) => paramName],
    (parameters, paramName) => {
        return parameters?.find((parameter) => parameter.code === paramName);
    }
);
