import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {changePassword, FormGenerator, isPasswordChangedSelector} from 'reservation-common-web';
import CustomCard from '../../shared/CustomCard';
import changePasswordFormConfig from './changePasswordFormConfig';
import {useDispatch, useSelector} from 'react-redux';

const ChangePasswordCard: React.FC = () => {
    const dispatch = useDispatch();
    const isPasswordChanged = useSelector(isPasswordChangedSelector);
    const changePasswordAction = (values: any): any => {
        const changePasswordPayload = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
        };
        dispatch(changePassword(changePasswordPayload));
    };
    useEffect(() => {
        if (isPasswordChanged) {
            alert('Password changed successfully');
        }
    }, [isPasswordChanged]);
    const {t} = useTranslation();
    const initialValues = {
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
    };

    return (
        <CustomCard
            title="accountView.passwordChange.title"
            content={
                <div className="account-form-container">
                    <FormGenerator
                        config={{
                            fields: changePasswordFormConfig(t),
                            initialValues,
                            onSubmit: changePasswordAction,
                            formId: 'change-password-form',
                            formClassName: 'auth-form',
                            submitButtonLabel: 'accountView.passwordChange.btn_save',
                            submitButtonClasses: 'btn-primary',
                        }}
                    />
                </div>
            }
        />
    );
};

export default ChangePasswordCard;
