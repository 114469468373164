import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator, IRestaurantConfigurationOutput} from 'reservation-common-web';
import CustomCard from '../../../shared/CustomCard';
import formAppearanceCardFormConfig from './formAppearanceCardFormConfig';
import {restaurantConfigurationSelector} from '../../../../store/selectors/restaurantSelector';
import {ISetFormAppearance, setFormAppearance, setIsFormAppearanceValid} from '../../../../store/reducers/restaurantSlice';

const FormAppearanceCard: React.FC = () => {
    const configuration: IRestaurantConfigurationOutput | null = useSelector(restaurantConfigurationSelector);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const handleSubmit = (values: any) => {
        const payload: ISetFormAppearance = {
            color: values.color.replace('#', ''),
            backgroundId: values.background,
        };
        if (configuration && configuration.color === values.color && configuration.background?.id === values.background) return;

        dispatch(setFormAppearance(payload));
    };

    const initialValues = {
        color: configuration?.color ? `#${configuration?.color}` : '#000000',
        background: configuration?.background?.fileUrls?.mediumThumb || '',
    };

    return (
        <CustomCard
            title="restaurantForm.formAppearanceCard.title"
            content={
                <div className="account-form-container">
                    <FormGenerator
                        config={{
                            fields: formAppearanceCardFormConfig(t),
                            initialValues,
                            onSubmit: handleSubmit,
                            debouncedChangeTimeout: 1000,
                            formId: 'change-form-presentation-form',
                            formClassName: '',
                        }}
                        isFormValid={(valid: boolean) => dispatch(setIsFormAppearanceValid(valid))}
                    />
                </div>
            }
        />
    );
};

export default FormAppearanceCard;
