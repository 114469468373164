import {ajax} from 'rxjs/ajax';
import {API_URL} from '../../config/entrypoint';

export function createMediaObjectAPI(authToken: string, formData: FormData) {
    return ajax({
        url: `${API_URL}/api/media_objects`,
        method: 'POST',
        body: formData,
        headers: {
            Authorization: 'Bearer ' + authToken,
        },
    });
}
