import {Observable} from 'rxjs';
import {reservationsAPI} from '../provider/reservationsAPI';
import {RestQueryParams} from '../base/queryParams';
import {IAccountBasicInfoOutput, IAccountLocaleInput} from '../../model/account';
import {IApiSingleResponseBase} from '../../model/base';

export function updateLocaleAPI(
    authToken: string | null,
    accountId: string,
    payload: IAccountLocaleInput
): Observable<IApiSingleResponseBase<IAccountBasicInfoOutput>> {
    let headers = undefined;
    const localePayload = {
        locale: payload.locale,
    };

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return reservationsAPI.put(`api/accounts/${accountId}/change_locale`, JSON.stringify(localePayload), new RestQueryParams(), headers);
}
