import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {initRestaurant} from '../../store/reducers/restaurantSlice';
import RestaurantFormContent from './RestaurantFormContent';
import RestaurantFormHeader from './RestaurantFormHeader';
import RestaurantFormFooter from './RestaurantFormFooter';

interface IRestaurantFormProps {}

const RestaurantForm: React.FC<IRestaurantFormProps> = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initRestaurant());
    }, []);

    return (
        <div className="row restaurant-form-wrapper">
            <div className="col-2">
                <RestaurantFormHeader />
                <RestaurantFormContent />
                <RestaurantFormFooter />
            </div>
            <div className="col-1" />
        </div>
    );
};

export default RestaurantForm;
