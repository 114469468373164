import {ErrorMessage, Field, useField, useFormikContext} from 'formik';
import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {CustomFieldProps} from '../../../model/form';
import FormikFieldWrapper from '../../FormikFieldRefWrapper';

const DateRangePickerField: React.FC<CustomFieldProps> = ({name, label, required = false, disabled = false}) => {
    const [field, meta, helpers] = useField(name);
    const {setFieldValue} = useFormikContext();
    const [fieldFocus, setFieldFocus] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const handleFocus = () => {
        setFieldFocus(true);
    };
    useEffect(() => {
        if (field.value) {
            const [start, end] = field.value.split(' - ');
            setStartDate(new Date(start));
            setEndDate(new Date(end));
        }
    }, [field.value]);

    const handleBlur = () => {
        setFieldFocus(false);
    };

    return (
        <div
            className={`form-control datepicker ${meta.touched && meta.error ? 'invalid' : ''} ${
                field.value || fieldFocus ? 'filled' : ''
            }`}
            key={name}>
            <DatePicker
                id={name}
                name={name}
                showPopperArrow={false}
                popperPlacement="bottom-start"
                onFocus={handleFocus}
                onBlur={handleBlur}
                popperClassName="datepicker-popper"
                placeholderText=""
                onChange={(val: any) => {
                    const [start, end] = val;
                    if (start && end) {
                        setFieldValue(name, `${start} - ${end}`);
                    }
                    setStartDate(start);
                    setEndDate(end);
                }}
                dateFormat="yyyy-MM-dd"
                disabled={disabled}
                startDate={startDate}
                endDate={endDate}
                selectsRange={true}
                customInput={
                    <FormikFieldWrapper
                        name={name}
                        placeholder=""
                        className={`form-input datepicker ${field.value || fieldFocus ? 'filled' : ''}`}
                    />
                }
            />
            <label htmlFor={name} className="form-label">
                {label} {required && <span className="label-required">*</span>}
            </label>

            <ErrorMessage name={name} component="div" className="error-message" />
        </div>
    );
};

export default DateRangePickerField;
