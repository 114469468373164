import React from 'react';
import {useTranslation} from 'react-i18next';
import {FormGenerator, accountLocaleSelector, changeLocale} from 'reservation-common-web';
import CustomCard from '../../shared/CustomCard';
import changeSettingsFormConfig from './changeSettingsFormConfig';
import {useDispatch, useSelector} from 'react-redux';

const SettingsCard: React.FC = () => {
    const dispatch = useDispatch();
    const changeSettings = (values: any) => {
        const localePayload = {locale: values.language};
        dispatch(changeLocale(localePayload));
    };
    const accountLocale = useSelector(accountLocaleSelector);
    const initialValues = {
        language: accountLocale ? accountLocale : 'pl_PL',
        currency: 'PLN',
    };
    const {t} = useTranslation();

    return (
        <CustomCard
            title="accountView.settings.title"
            content={
                <div className="account-form-container">
                    <FormGenerator
                        config={{
                            fields: changeSettingsFormConfig(t),
                            initialValues,
                            onSubmit: changeSettings,
                            formId: 'change-settings-form',
                            formClassName: '',
                            submitButtonLabel: 'accountView.passwordChange.btn_save',
                            submitButtonClasses: 'btn-primary',
                        }}
                    />
                </div>
            }
        />
    );
};

export default SettingsCard;
