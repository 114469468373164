import React from 'react';

const UserIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.98722 14.7454C5.44347 13.6705 6.50869 12.9166 7.75 12.9166H12.25C13.4913 12.9166 14.5565 13.6705 15.0128 14.7454M13 7.29163C13 8.94848 11.6569 10.2916 10 10.2916C8.34315 10.2916 7 8.94848 7 7.29163C7 5.63477 8.34315 4.29163 10 4.29163C11.6569 4.29163 13 5.63477 13 7.29163ZM17.5 9.16663C17.5 13.3088 14.1421 16.6666 10 16.6666C5.85786 16.6666 2.5 13.3088 2.5 9.16663C2.5 5.02449 5.85786 1.66663 10 1.66663C14.1421 1.66663 17.5 5.02449 17.5 9.16663Z"
            stroke="#848A94"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default UserIcon;
