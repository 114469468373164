import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {noTokenCheckReservationsAPI} from '../provider/noTokenCheckReservationsAPI';
import { ILoginInput, ILoginOutput } from '../../model/user';

export function createLoginAPI(loginInput: ILoginInput): Observable<ILoginOutput> {
    return noTokenCheckReservationsAPI.post(
        'api/auth/token',
        loginInput,
        new RestQueryParams()
    );
}
