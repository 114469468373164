import {isNullOrUndefined} from './runtimeUtils';

export const isSameCollection = <T = any>(a?: T[], b?: T[], recursive: boolean = false): boolean => {
    if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
        return a === b;
    }
    if (!Array.isArray(a) || !Array.isArray(b)) {
        return false;
    }
    if (a.length !== b.length) {
        return false;
    }

    return a.every((value, index) => {
        const compared = b[index];
        if (isNullOrUndefined(compared)) {
            return false;
        }

        return isSameValue(a, b, recursive);
    });
};

export const isSameValue = <T>(a?: T, b?: T, recursive: boolean = true): boolean => {
    if (Object.is(a, b)) {
        return true;
    }
    if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
        return a === b;
    }
    if (typeof a !== 'object' || typeof b !== 'object') {
        return false;
    }
    const aKeys = Object.keys(a).filter((key) => a.hasOwnProperty(key)),
        bKeys = Object.keys(b).filter((key) => b.hasOwnProperty(key));
    if (aKeys.length !== bKeys.length) {
        return false;
    }

    return aKeys.every((key) => {
        const aValue = a[key],
            bValue = b[key];

        if (Object.is(aValue, bValue)) {
            return true;
        }
        if (!recursive) {
            return false;
        }

        return isSameValue(aValue, bValue, true);
    });
};

export const arraysAreSame = (array1: string[], array2: string[]) => {
    if (array1.length !== array2.length) return false;
    return array1.every((element, index) => element === array2[index]);
};
