import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LanguageLocale} from '../../constants/locales';

export interface ISagaState {
    activeLanguage: LanguageLocale;
    showLoader: boolean;
}

export interface IChangeLanguage {
    activeLanguage: LanguageLocale;
}

export interface IChangeShowLoader {
    readonly payload: {
        showLoader: boolean;
    };
}

const initialState: ISagaState = {
    activeLanguage: LanguageLocale.PL,
    showLoader: false,
};

const sagaSlice = createSlice({
    name: 'saga',
    initialState: initialState,
    reducers: {
        changeActiveLanguage: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeLanguage>) => {
                return {
                    ...state,
                    activeLanguage: action.payload.activeLanguage,
                };
            },
            prepare(activeLanguage: LanguageLocale) {
                return {
                    payload: {activeLanguage: activeLanguage},
                };
            },
        },
        changeShowLoader: {
            reducer: (state: ISagaState, action: IChangeShowLoader) => {
                return {
                    ...state,
                    showLoader: action.payload.showLoader,
                };
            },
            prepare(showLoader: boolean) {
                return {
                    payload: {showLoader: showLoader},
                };
            },
        },
    },
});

export const {changeActiveLanguage, changeShowLoader} = sagaSlice.actions;

export default sagaSlice.reducer;
