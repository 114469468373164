import {Form, Formik} from 'formik';
import React, {useEffect, useRef} from 'react';
import * as Yup from 'yup';
import {FormGeneratorConfig, FormikFieldConfig, FormikFieldTypes} from '../../model/form';
import CheckboxField from '../FormControls/Checkbox';
import {ColorPickerField} from '../FormControls/ColorPicker';
import DatePickerField from '../FormControls/Date';
import Password from '../FormControls/Password';
import RichTextField from '../FormControls/RichText';
import FormikSelectField from '../FormControls/Select';
import SwitchField from '../FormControls/Switch';
import TextField from '../FormControls/Text';
import {TextareaField} from '../FormControls/TextArea';
import TimePickerField from '../FormControls/Time';
import Translation from '../Translation';
import FileFormControl from '../FormControls/File';
import DateRangePickerField from '../FormControls/DateRange';

const renderField = (formikField: FormikFieldConfig) => {
    switch (formikField.type) {
        case FormikFieldTypes.TEXT:
        case FormikFieldTypes.EMAIL:
        case FormikFieldTypes.NUMBER:
            return (
                <TextField
                    key={formikField.name}
                    name={formikField.name}
                    label={formikField.label}
                    type={formikField.type}
                    isRequired={formikField.isRequired}
                    disabled={formikField.disabled}
                    placeholder={formikField.placeholder}
                    validation={formikField.validation}
                    autofocus={formikField.autofocus}
                />
            );
        case FormikFieldTypes.PASSWORD:
            return <Password key={formikField.name} {...formikField} />;
        case FormikFieldTypes.TEXTAREA:
            return <TextareaField key={formikField.name} {...formikField} />;
        case FormikFieldTypes.COLORPICKER:
            return (
                <ColorPickerField
                    key={formikField.name}
                    label={formikField.label}
                    name={formikField.name}
                    disabled={formikField.disabled}
                />
            );
        case FormikFieldTypes.SELECT:
            return (
                <FormikSelectField
                    key={formikField.name}
                    name={formikField.name}
                    options={formikField.options}
                    isRequired={formikField.isRequired}
                    placeholder={formikField.placeholder}
                    isDisabled={formikField.disabled}
                    label={formikField.label}
                    className={formikField.className}
                    isSearchable={formikField.isSearchable}
                />
            );
        case FormikFieldTypes.FILE:
            return <FileFormControl key={formikField.name} {...formikField} />;
        case FormikFieldTypes.CHECKBOX:
            return <CheckboxField key={formikField.name} {...formikField} />;
        case FormikFieldTypes.DATE:
            return (
                <DatePickerField
                    key={formikField.name}
                    name={formikField.name}
                    label={formikField.label}
                    required={formikField.isRequired}
                    disabled={formikField.disabled}
                    excludeDates={formikField.excludeDates}
                    disabledKeyboardNavigation={formikField.disabledKeyboardNavigation}
                    maxDate={formikField.maxDate}
                    minDate={formikField.minDate}
                />
            );
        case FormikFieldTypes.DATERANGE:
            return (
                <DateRangePickerField
                    key={formikField.name}
                    name={formikField.name}
                    label={formikField.label}
                    required={formikField.isRequired}
                    disabled={formikField.disabled}
                />
            );
        case FormikFieldTypes.TIME:
            return (
                <TimePickerField
                    key={formikField.name}
                    name={formikField.name}
                    label={formikField.label}
                    required={formikField.isRequired}
                    disabled={formikField.disabled}
                    disabledKeyboardNavigation={formikField.disabledKeyboardNavigation}
                />
            );
        case FormikFieldTypes.RICH_TEXT:
            return (
                <RichTextField
                    key={formikField.name}
                    name={formikField.name}
                    label={formikField.label}
                    required={formikField.isRequired}
                    disabled={formikField.disabled}
                />
            );
        case FormikFieldTypes.SWITCH:
            return <SwitchField key={formikField.name} {...formikField} />;
        case FormikFieldTypes.CUSTOM:
            return <React.Fragment key={formikField.name}>{formikField.customElement}</React.Fragment>;
        default:
            return null;
    }
};

const FormGenerator: React.FC<{config: FormGeneratorConfig; isFormValid?: (valid: boolean) => void}> = ({config, isFormValid}) => {
    const validationSchema = Yup.object(
        config.fields.reduce((schema, field) => {
            if (field.validation) {
                schema[field.name] = field.validation;
            }
            return schema;
        }, {} as {[key: string]: any})
    );
    const previousValuesRef = useRef(config.initialValues);
    const debouncedChangeTimeout = useRef<number | null>(null);

    const handleDebouncedChange = (values: any) => {
        if (debouncedChangeTimeout.current) {
            clearTimeout(debouncedChangeTimeout.current);
        }

        debouncedChangeTimeout.current = setTimeout(() => {
            config.onSubmit(values);
        }, config.debouncedChangeTimeout);
    };
    return (
        <Formik
            initialValues={config.initialValues}
            validationSchema={validationSchema}
            onSubmit={config.onSubmit}
            validateOnChange={true}
            validateOnBlur={false}>
            {({isValid, dirty, values}) => {
                useEffect(() => {
                    if (JSON.stringify(previousValuesRef.current) !== JSON.stringify(values)) {
                        if (config.onChange) {
                            config.onChange(values);
                        }
                        if (config.debouncedChangeTimeout) {
                            handleDebouncedChange(values);
                        }
                        previousValuesRef.current = values;
                    }
                }, [values]);
                useEffect(() => {
                    if (isFormValid) {
                        isFormValid(isValid);
                    }
                }, [isValid, dirty]);

                return (
                    <Form id={config.formId} className={`form-container ${config.formClassName}`}>
                        {config.fields.map(renderField)}
                        {config.submitButtonLabel ? (
                            <div className={`btn-container ${config.buttonContainerClasses ? config.buttonContainerClasses : ''}`}>
                                <button
                                    type="submit"
                                    className={config.submitButtonClasses ? config.submitButtonClasses : ''}
                                    form={config.formId}
                                    disabled={!isValid || !dirty}>
                                    <Translation text={config.submitButtonLabel} />
                                </button>
                            </div>
                        ) : null}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FormGenerator;
