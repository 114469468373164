import {ErrorMessage, Field, FieldProps} from 'formik';
import {CustomFieldProps} from '../../../model/form';
import React from 'react';

export const ColorPickerField: React.FC<CustomFieldProps> = ({label, name, disabled}) => {
    return (
        <div className="form-control color-picker">
            <label htmlFor={name} className="form-label">
                {label}
            </label>
            <Field name={name}>
                {({field, form}: FieldProps) => (
                    <div className="color-picker-wrapper">
                        <input
                            type="color"
                            {...field}
                            value={field.value || '#000000'}
                            disabled={disabled}
                            className="form-input color-picker-input"
                            onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                        />
                        <input
                            type="text"
                            {...field}
                            value={field.value || '#000000'}
                            disabled={disabled}
                            className="form-input color-picker-text"
                            onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                        />
                    </div>
                )}
            </Field>
            <ErrorMessage name={name} component="div" className="form-error" />
        </div>
    );
};
