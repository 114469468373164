import * as React from 'react';
import {useTranslation} from 'react-i18next';

interface ITranslationProps {
    text: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config?: {[key: string]: any};
    translationKey?: string;
}

const Translation: React.FC<ITranslationProps> = ({text, config, translationKey}) => {
    const {t} = useTranslation();

    return <React.Fragment key={translationKey}>{t(text, config)}</React.Fragment>;
};

export default Translation;
