import {combineEpics} from 'redux-observable';
import reservationsEpic from './reservationsEpic';
import {authEpic, accountEpic, changePasswordEpic, loginEpic, reauthenticateEpic, renewAuthTokenEpic} from 'reservation-common-web';
import restaurantEpic from './restaurantEpic';

export const rootEpic = combineEpics(
    loginEpic,
    renewAuthTokenEpic,
    authEpic,
    reauthenticateEpic,
    changePasswordEpic,
    accountEpic,
    reservationsEpic,
    restaurantEpic
);
