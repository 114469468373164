import React from 'react';
import {Field} from 'formik';
import {Translation, IDayOpenHoursInput} from 'reservation-common-web';
import {HourControlTypes, IWeekDay, IWeekDayEvent} from '../../OpenHoursCard';
import HourControl from './HourControl';

interface IWeekDayRowProps {
    day: IWeekDay;
    value?: IDayOpenHoursInput | null;
    touched: any;
    errors: any;
    onChange: (e: IWeekDayEvent) => void;
}

const WeekDayRow: React.FC<IWeekDayRowProps> = (props: IWeekDayRowProps) => {
    const renderTimeControls = () => {
        if (!props.value) {
            return (
                <p className="not-available-info">
                    <Translation text="restaurantForm.openHoursCard.closed" />
                </p>
            );
        }

        return (
            <div className="time-controls-wrapper">
                <HourControl
                    type={HourControlTypes.from}
                    day={props.day}
                    value={props.value.from}
                    touched={props.touched}
                    errors={props.errors}
                    onChange={(val: Date) => {
                        props.onChange({
                            type: HourControlTypes.from,
                            value: val,
                            day: props.day,
                        });
                    }}
                />
                <HourControl
                    type={HourControlTypes.to}
                    day={props.day}
                    value={props.value.to}
                    touched={props.touched}
                    errors={props.errors}
                    onChange={(val: Date) => {
                        props.onChange({
                            type: HourControlTypes.to,
                            value: val,
                            day: props.day,
                        });
                    }}
                />
            </div>
        );
    };

    return (
        <div key={props.day} className="form-control switch week-day">
            <label className="switch-label">
                <Field
                    type="checkbox"
                    className="switch-input"
                    name={`${props.day}`}
                    value={!!props.value}
                    checked={!!props.value}
                    onChange={() => {
                        console.log('onChange');

                        props.onChange({
                            type: HourControlTypes.switch,
                            value: !props.value,
                            day: props.day,
                        });
                    }}
                />
                <div className="switch-slider" />
                <span className="switch-label-text">
                    <Translation text={`restaurantForm.openHoursCard.weekdays.${props.day}`} />
                </span>
            </label>
            {renderTimeControls()}
        </div>
    );
};

export default WeekDayRow;
