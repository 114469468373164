import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Quill from 'quill';

interface RichTextComponentProps {
    value: string;
    onChange: (value: string) => void;
}

const RichTextComponent = forwardRef<HTMLDivElement, RichTextComponentProps>(({ value, onChange }, ref) => {
    const quillRef = useRef<HTMLDivElement>(null);
    const quillInstanceRef = useRef<Quill | null>(null);
    const textChangeHandlerRef = useRef<() => void>(() => { });

    useImperativeHandle(ref, () => quillRef.current!);

    useEffect(() => {
        if (quillRef.current) {
            quillInstanceRef.current = new Quill(quillRef.current, {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],
                        ['link', 'image', 'video', 'formula'],
                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction
                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                    ],
                },
            });

            textChangeHandlerRef.current = () => {
                onChange(quillInstanceRef.current!.root.innerHTML);
            };

            quillInstanceRef.current.on('text-change', textChangeHandlerRef.current);
            quillInstanceRef.current.root.innerHTML = value;
        }

        return () => {
            if (quillInstanceRef.current) {
                quillInstanceRef.current.off('text-change', textChangeHandlerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (quillInstanceRef.current && quillInstanceRef.current.root.innerHTML !== value) {
            quillInstanceRef.current.root.innerHTML = value;
        }
    }, [value]);

    return <div ref={quillRef} />;
});

export default RichTextComponent;