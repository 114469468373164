import React, {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import {IPaginationProps} from '../../model/common';

type Props = IPaginationProps;

const Pagination: React.FC<Props> = ({listMetadata, changePage, itemsPerPage}) => {
    const [selectedItemsPerPage] = useState<number>(10),
        [selectedPage, setSelectedPage] = useState<number>(0),
        [pageCount, setPageCount] = useState<number>(1);

    const setTotalPageCount = () => {
        if (listMetadata) {
            const lastItemUrl = listMetadata['hydra:last'];

            if (lastItemUrl) {
                const str = lastItemUrl.split('?').pop(),
                    searchParams = queryString.parse(str),
                    totalPageNumber = searchParams && searchParams.page ? Number(searchParams.page) : 0;

                setPageCount(totalPageNumber);
            }
        } else {
            setPageCount(0);
        }
    };

    useEffect(() => {
        setTotalPageCount();
    }, [listMetadata]);

    useEffect(() => {
        let localSearchParams = {
            itemsPerPage: itemsPerPage ? itemsPerPage : selectedItemsPerPage,
            page: selectedPage + 1,
        };
        changePage(localSearchParams);
    }, [selectedPage]);

    const onPageChange = (data: {[key: string]: any}) => {
        let selectedPage = Number(data.selected);
        setSelectedPage(selectedPage);
    };

    const renderPagination = () => {
        const prevBtn =
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACoSURBVHgB7dCxDcIwEAXQfyckRINCR4U8gjdAHoEJgA0ogQWgzAhhEosNGAHBAimhwOZIQeckF9GRL51s6awn3wF9so3PpltvUv0BWiLjEXu5GqlJJ+iLRNiIsE69Iw1yP7pCDWmQTzjV0CC1EEUqq5M4okXS0OO1kB9dpIrZ7rxEQ2qXbWRPYSgjEqzU6naYnzpBGoyboGvuSn4GV40J5PhFzN5b/F/eOlVAs3keErkAAAAASUVORK5CYII=',
            nextBtn =
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACwSURBVHgB5dCxDcIwEAXQuwtIUIDcIzJDGMGTABtAgUQ2oMwGZANGsJDoYQQkFrBoUsVHLOojtungNy7u9E7fAP+X2c4UMfskDTIik5fnq9oYBd9AjG4LDMV0TCYEw0/D+d6sEOjYbd2ejdO20jYJisGoD3ocdM3g1r7mZJSdpL1eyAdxyO+XVTKUl5clcFv7atRVE4/FIPeUz45BfORqrq1CEZ+BeAHdAhqwIciP5wW7qE+ahJWhdwAAAABJRU5ErkJggg==';

        return (
            <nav aria-label="Page navigation" className={`${pageCount === 0 ? 'hidden' : ''} pagination-container`}>
                <div>
                    <ReactPaginate
                        previousLabel={<img src={prevBtn} alt="Previous pagination button" />}
                        nextLabel={<img src={nextBtn} alt="Next pagination button" />}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        // initialPage={this.state.selectedPage}
                        onPageChange={onPageChange}
                        containerClassName={`pagination custom-pagination`}
                        activeClassName="active"
                        previousClassName="prev-link"
                        nextClassName="next-link"
                        disableInitialCallback={true}
                        forcePage={selectedPage}
                    />
                </div>
            </nav>
        );
    };

    return <>{renderPagination()}</>;
};

export default Pagination;
