import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes} from 'reservation-common-web';

const reservationDetailsCardFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'maxPeopleInReservation',
            label: t('restaurantForm.reservationCard.form.labels.maxPeopleInReservation'),
            type: FormikFieldTypes.NUMBER,
            validation: Yup.number().min(0).required(t('validation.required')),
            className: '',
        },
        {
            name: 'maxPeopleInSingleReservation',
            label: t('restaurantForm.reservationCard.form.labels.maxPeopleInSingleReservation'),
            type: FormikFieldTypes.NUMBER,
            validation: Yup.number().min(0).required(t('validation.required')),
            className: '',
        },
        {
            name: 'minReservationHour',
            label: t('restaurantForm.reservationCard.form.labels.minReservationHour'),
            type: FormikFieldTypes.TIME,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'maxReservationHour',
            label: t('restaurantForm.reservationCard.form.labels.maxReservationHour'),
            type: FormikFieldTypes.TIME,
            validation: Yup.string().required(t('validation.required')),
            className: '',
        },
        {
            name: 'reservationInterval',
            label: t('restaurantForm.reservationCard.form.labels.reservationInterval'),
            type: FormikFieldTypes.SELECT,
            validation: Yup.string().required(t('validation.required')),
            options: [
                {label: t('15 min'), value: 15},
                {label: t('30 min'), value: 30},
                {label: t('60 min'), value: 60},
            ],
            className: '',
        },
    ];
};

export default reservationDetailsCardFormConfig;
