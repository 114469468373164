import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {reservationsAPI} from '../provider/reservationsAPI';
import {IConfirmRegistrationOutput} from '../../model/user';
import {IApiSingleResponseBase} from '../../model/base';

export function createConfirmEmailChangeAPI(authenticationToken: string): Observable<IApiSingleResponseBase<IConfirmRegistrationOutput>> {
    return reservationsAPI.post(`api/users/confirm_email_change`, {}, new RestQueryParams(), {
        'X-Authentication-Token': authenticationToken,
    });
}
