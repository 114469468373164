import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRegisterUserInput, IRequestUserSetNewPasswordInput, UserRegistrationRole, UserRole} from '../../model/user';

export interface IAuthEntry {
    username: string | null;
    authToken: string | null;
    refreshToken: string | null;
}

export interface IAuthTokens {
    authToken: string;
    refreshToken: string;
}

export interface IRegistrationInput {
    username: string;
    password: string;
    locale: string;
    type: UserRegistrationRole;
    returnUrl: string;
}

export interface IAuthState {
    username: string | null;
    authToken: string | null;
    refreshToken: string | null;
    restaurantToken: string | null;
    error: string | null;
    isAuthenticated: boolean;
    userRoles: UserRole[] | null;
    registrationData: IRegistrationInput | null;
    isAuthPageLoading: boolean;
}

export interface ISetAuthTokens {
    readonly authToken: string | null;
    readonly refreshToken: string | null;
}

export interface ISetAuthState {
    readonly username: string | null;
    readonly authToken: string | null;
    readonly refreshToken: string | null;
    readonly isAuthenticated: boolean;
    readonly userRoles: UserRole[] | null;
}

export interface ISetAuthError {
    readonly error: string;
}

export interface IChangeInitialLoading {
    readonly initialLoading: boolean;
}

export interface IChangeIsAuthenticated {
    readonly isAuthenticated: boolean;
}

export interface IRenewAuthToken {
    readonly authToken: string;
    readonly refreshToken: string;
    readonly silentRefresh?: boolean;
}

export interface IInitApp {
    readonly refreshToken: string;
    readonly userRole: UserRole;
}

export interface IRegisterUser {
    readonly registrationPayload: IRegisterUserInput;
    readonly invitationToken?: string | null;
}

export interface IRequestNewPassword {
    readonly requestNewPasswordPayload: IRequestUserSetNewPasswordInput;
    readonly panelAccessRole: UserRole;
}

export interface IConfirmRegistration {
    readonly registrationToken: string;
    readonly panelAccessRole: UserRole;
}

export interface ISetNewPassword {
    readonly authToken: string;
    readonly password: string;
    readonly panelAccessRole: UserRole;
}

export interface IChangeIsAuthPageLoading {
    readonly isLoading: boolean;
}

const initialState: IAuthState = {
    username: null,
    authToken: null,
    refreshToken: null,
    restaurantToken: null,
    error: null,
    isAuthenticated: false,
    registrationData: null,
    userRoles: null,
    isAuthPageLoading: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuthState: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthState>) => {
                return {
                    ...state,
                    username: action.payload.username || null,
                    authToken: action.payload.authToken || null,
                    refreshToken: action.payload.refreshToken || null,
                    isAuthenticated: action.payload.isAuthenticated || false,
                    userRoles: action.payload.userRoles || null,
                };
            },
            prepare(username: any, authToken: any, refreshToken: any, isAuthenticated: boolean, userRoles: UserRole[]) {
                return {
                    payload: {
                        username: username,
                        authToken: authToken,
                        refreshToken: refreshToken,
                        isAuthenticated: isAuthenticated,
                        userRoles: userRoles,
                    },
                };
            },
        },
        setAuthStateFailure: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthError>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        setAuthTokens: {
            reducer: (state: IAuthState, action: PayloadAction<ISetAuthTokens>) => {
                return {
                    ...state,
                    authToken: action.payload.authToken,
                    refreshToken: action.payload.refreshToken,
                };
            },
            prepare(authToken: string, refreshToken: string) {
                return {
                    payload: {
                        authToken: authToken,
                        refreshToken: refreshToken,
                    },
                };
            },
        },
        initApp: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                };
            },
            prepare(refreshToken: string, userRole: UserRole) {
                return {
                    payload: {
                        refreshToken: refreshToken,
                        userRole: userRole,
                    },
                };
            },
        },
        changeIsAuthenticated: {
            reducer: (state: IAuthState, action: PayloadAction<IChangeIsAuthenticated>) => {
                return {
                    ...state,
                    isAuthenticated: action.payload.isAuthenticated,
                };
            },
            prepare(isAuthenticated: boolean) {
                return {
                    payload: {
                        isAuthenticated: isAuthenticated,
                    },
                };
            },
        },
        setRegistrationData: {
            reducer: (state: IAuthState, action: PayloadAction<IRegistrationInput | null>) => {
                return {
                    ...state,
                    registrationData: action.payload,
                };
            },
            prepare(registrationData: IRegistrationInput | null) {
                return {
                    payload: registrationData,
                };
            },
        },
        logout: {
            reducer: () => {
                return initialState;
            },
            prepare(logout: true) {
                return {
                    payload: {logout},
                };
            },
        },
        renewAuthToken: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                };
            },
            prepare(refreshToken: string, authToken: string, silentRefresh?: boolean) {
                return {
                    payload: {
                        authToken: authToken,
                        refreshToken: refreshToken,
                        silentRefresh,
                    },
                };
            },
        },
        registerUser: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                    isAuthPageLoading: true,
                };
            },
            prepare(registrationPayload: IRegisterUserInput, invitationToken?: string | null) {
                return {
                    payload: {
                        registrationPayload: registrationPayload,
                        invitationToken,
                    },
                };
            },
        },
        confirmRegistration: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                    isAuthPageLoading: true,
                };
            },
            prepare(registrationToken: string, panelAccessRole: UserRole) {
                return {
                    payload: {
                        registrationToken: registrationToken,
                        panelAccessRole,
                    },
                };
            },
        },
        requestNewPassword: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                    isAuthPageLoading: true,
                };
            },
            prepare(requestNewPasswordPayload: IRequestUserSetNewPasswordInput, panelAccessRole: UserRole) {
                return {
                    payload: {
                        requestNewPasswordPayload: requestNewPasswordPayload,
                        panelAccessRole,
                    },
                };
            },
        },
        setNewPassword: {
            reducer: (state: IAuthState) => {
                return {
                    ...state,
                    isAuthPageLoading: true,
                };
            },
            prepare(authToken: string, password: string, panelAccessRole: UserRole) {
                return {
                    payload: {
                        authToken: authToken,
                        password: password,
                        panelAccessRole,
                    },
                };
            },
        },
        changeIsAuthPageLoading: {
            reducer: (state: IAuthState, action: PayloadAction<IChangeIsAuthPageLoading>) => {
                return {
                    ...state,
                    isAuthPageLoading: action.payload.isLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {
                        isLoading: isLoading,
                    },
                };
            },
        },
    },
});

export const {
    setAuthState,
    setAuthTokens,
    initApp,
    setAuthStateFailure,
    changeIsAuthenticated,
    renewAuthToken,
    logout,
    registerUser,
    confirmRegistration,
    requestNewPassword,
    setNewPassword,
    changeIsAuthPageLoading,
    setRegistrationData,
} = authSlice.actions;

export default authSlice.reducer;
