export const formatHoursAndMinutes = (hours: number, minutes: number): string => {
    return `${formatTimeDigits(hours)}:${formatTimeDigits(minutes)}`;
};

export const formatTimeDigits = (digits: number): string => {
    if (0 === digits) {
        return '00';
    }
    if (10 > digits) {
        return `0${digits}`;
    }

    return digits.toFixed(0);
};

export const parseDatepickerTime = (time: string | undefined): Date | null => {
    if (!time) {
        return null;
    }
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
};
export const formatTimeToHHmm = (date: Date): string => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};
