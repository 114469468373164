import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator, IRoomInput, formatTimeToHHmm} from 'reservation-common-web';
import CustomCard from '../../../shared/CustomCard';
import reservationDetailsCardFormConfig from './reservationDetailsCardFormConfig';
import {restaurantRoomsSelector} from '../../../../store/selectors/restaurantSelector';
import {setIsRestaurantDetailsValid, setRoomDivision} from '../../../../store/reducers/restaurantSlice';

const ReservationDetailsCard: React.FC = () => {
    const dispatch = useDispatch();
    const saveReservationDetails = (values: any) => {
        const hourStartFormatted = values.minReservationHour ? formatTimeToHHmm(values.minReservationHour) : '';
        const hourEndFormatted = values.maxReservationHour ? formatTimeToHHmm(values.maxReservationHour) : '';
        const payload: IRoomInput = {
            id: null,
            name: 'default room',
            isDefault: true,
            maxCount: values.maxPeopleInSingleReservation,
            maxCountPerReservation: values.maxPeopleInReservation,
            hourStart: hourStartFormatted,
            hourEnd: hourEndFormatted,
            step: values.reservationInterval,
            prepayRequired: false,
            prepayPerPerson: {
                amount: '1000',
                currency: {
                    name: 'PLN',
                },
            },
        };
        dispatch(setRoomDivision([payload]));
    };

    const rooms = useSelector(restaurantRoomsSelector);
    const room = rooms && rooms.length ? rooms[0] : null;
    const initialValues = {
        maxPeopleInReservation: room?.maxCount || '',
        maxPeopleInSingleReservation: room?.maxCountPerReservation || '',
        minReservationHour: room?.hourStart ? new Date(`1970-01-01T${room.hourStart}:00`) : '',
        maxReservationHour: room?.hourEnd ? new Date(`1970-01-01T${room.hourEnd}:00`) : '',
        reservationInterval: room?.step || '',
    };
    const {t} = useTranslation();

    return (
        <CustomCard
            title="restaurantForm.reservationCard.title"
            content={
                <div className="account-form-container">
                    <FormGenerator
                        config={{
                            fields: reservationDetailsCardFormConfig(t),
                            initialValues,
                            onSubmit: saveReservationDetails,
                            debouncedChangeTimeout: 1000,
                            formId: 'change-reservation-details-form',
                            formClassName: '',
                        }}
                        isFormValid={(valid: boolean) => dispatch(setIsRestaurantDetailsValid(valid))}
                    />
                </div>
            }
        />
    );
};

export default ReservationDetailsCard;
