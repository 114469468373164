import {useState, useEffect, useRef} from 'react';

export function useOutsideClickHook(initialIsVisible: any) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref: any = useRef(null);

    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return {ref, isComponentVisible, setIsComponentVisible};
}
