import {createSelector} from '@reduxjs/toolkit';
import {ICountryState} from '../reducers/countrySlice';
import {CommonRootState} from '../reducers';

export const selectCountry = (state: CommonRootState): ICountryState => {
    return state.country;
};

export const countriesSelector = createSelector([selectCountry], (state: ICountryState) => state.countries);

export const countriesLoadingSelector = createSelector([selectCountry], (state: ICountryState) => state.isLoading);

export const countriesInitializedSelector = createSelector([selectCountry], (state: ICountryState) => state.isInitialized);

export const countriesErrorSelector = createSelector([selectCountry], (state: ICountryState) => state.error);

export const countryByIdSelector = createSelector([countriesSelector, (state, id) => id], (countries, id) => {
    return countries?.find((country) => country.id === id);
});
