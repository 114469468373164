import {Observable} from 'rxjs';
import {reservationsAPI} from '../provider/reservationsAPI';
import {RestQueryParams} from '../base/queryParams';
import {IAccountMeOutput} from '../../model/account';
import {IApiSingleResponseBase} from '../../model/base';

export function getAccountDataAPI(authToken: string): Observable<IApiSingleResponseBase<IAccountMeOutput>> {
    return reservationsAPI.get(`api/accounts/me`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
