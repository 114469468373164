import {Observable} from 'rxjs';
import {reservationsAPI} from '../provider/reservationsAPI';
import {RestQueryParams} from '../base/queryParams';
import {IApiSingleResponseBase} from '../../model/base';
import {ICanOutput} from '../../model/common';

export function getCanDeleteAccountAPI(authToken: string, id: string): Observable<IApiSingleResponseBase<ICanOutput>> {
    return reservationsAPI.get(`api/accounts/${id}/can_delete`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
