import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {IRestaurantInput, IRestaurantOutput} from '../../model/restaurant';
import {reservationsAPI} from '../provider/reservationsAPI';
import {IApiSingleResponseBase} from '../../model/base';

export const updateRestaurantAPI: ApiOperationResponseFunction<IRestaurantOutput> = (
    authToken: string,
    payload: {
        id: string;
        restaurant: IRestaurantInput;
    }
): Observable<IApiSingleResponseBase<IRestaurantOutput>> => {
    return reservationsAPI.put(`api/restaurants/${payload.id}`, payload.restaurant, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
