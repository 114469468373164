import React from 'react';
import {ErrorMessage} from 'formik';
import DatePicker from 'react-datepicker';
import {Translation, parseDatepickerTime} from 'reservation-common-web';
import {HourControlTypes, IWeekDay} from '../../../OpenHoursCard';

interface IHourControlProps {
    day: IWeekDay;
    value: string;
    touched: any;
    errors: any;
    onChange: (date: Date) => void;
    type: HourControlTypes;
}

const HourControl: React.FC<IHourControlProps> = (props: IHourControlProps) => {
    return (
        <div
            className={`form-control datepicker timepicker ${props.touched && props.errors ? 'invalid' : ''} ${
                props.value ? 'filled' : ''
            }`}
            key={`${props.day}.${props.type}`}>
            <DatePicker
                selected={parseDatepickerTime(props.value)}
                onChange={(val: any) => props.onChange(val)}
                showTimeSelect
                showPopperArrow={false}
                popperClassName="datepicker-popper"
                popperPlacement="bottom-start"
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Time"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                disabled={false}
                disabledKeyboardNavigation={true}
                customInput={
                    <input
                        id={`${props.type}${props.day}`}
                        name={`${props.type}${props.day}`}
                        className={`form-input timepicker ${props.value ? 'filled' : ''}`}
                    />
                }
            />
            <label htmlFor={`${props.type}${props.day}`} className="form-label">
                <Translation text={`restaurantForm.openHoursCard.${props.type}`} />
                <span className="label-required">*</span>
            </label>

            <ErrorMessage name={`${props.type}${props.day}`} component="div" className="error-message" />
        </div>
    );
};

export default HourControl;
