import {ErrorMessage, useField, useFormikContext} from 'formik';
import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FormikFieldWrapper from '../../FormikFieldRefWrapper';

interface TimePickerFieldProps {
    name: string;
    label: string;
    required?: boolean;
    disabled?: boolean;
    disabledKeyboardNavigation?: boolean;
}

const TimePickerField: React.FC<TimePickerFieldProps> = ({
    name,
    label,
    required = false,
    disabled = false,
    disabledKeyboardNavigation = false,
}) => {
    const [field, meta, helpers] = useField(name);
    const {setFieldValue} = useFormikContext();

    const [fieldFocus, setFieldFocus] = useState(false);

    const handleFocus = () => {
        setFieldFocus(true);
    };

    const handleBlur = () => {
        setFieldFocus(false);
    };
    return (
        <div
            className={`form-control datepicker timepicker ${meta.touched && meta.error ? 'invalid' : ''} ${
                field.value || fieldFocus ? 'filled' : ''
            }`}
            key={name}>
            <DatePicker
                selected={(field.value && new Date(field.value)) || null}
                onChange={(val: any) => setFieldValue(name, val)}
                showTimeSelect
                onFocus={handleFocus}
                onBlur={handleBlur}
                showPopperArrow={false}
                popperClassName="datepicker-popper"
                popperPlacement="bottom-start"
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Time"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                disabled={disabled}
                disabledKeyboardNavigation={disabledKeyboardNavigation}
                customInput={
                    <FormikFieldWrapper name={name} placeholder="" className={`form-input datepicker ${field.value ? 'filled' : ''}`} />
                }
            />
            <label htmlFor={name} className="form-label">
                {label} {required && <span className="label-required">*</span>}
            </label>

            <ErrorMessage name={name} component="div" className="error-message" />
        </div>
    );
};

export default TimePickerField;
