import {Observable} from 'rxjs';
import {publishReplay, refCount} from 'rxjs/operators';
import memoizee from 'memoizee';

export function memoizeObservable<T = any>(
    toWrap: (...args: any[]) => Observable<T>,
    options?: memoizee.Options<any>
): (...args: any[]) => Observable<T> {
    return memoizee((...args: any[]) => toWrap(...args).pipe(publishReplay(1), refCount()), Object.assign({}, options));
}
