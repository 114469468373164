import React from 'react';
import {Translation} from 'reservation-common-web';
import WidgetLinkSnippet from './WidgetLinkSnippet';

const RestaurantFormHeader: React.FC = () => {
    return (
        <div className="header">
            <h2 className="title">
                <Translation text="restaurantForm.form" />
            </h2>
            <WidgetLinkSnippet />
        </div>
    );
};

export default RestaurantFormHeader;
