const en = {
    app: {
        title: 'Reservation Spot',
    },
    navbar: {
        appTitle: 'Reservation Spot',
        appSubtitle: 'Just reserve it!',
        menu: {
            reservations: 'Reservations',
            form: 'Form',
            account: 'Account',
            logout: 'Logout',
        },
    },
    premium_badge: {
        label: 'Go Premium',
    },
    form: {
        labels: {
            checkbox: `Zapoznałem się i akceptuję <a href="https://example.com/regulamin" target="_blank">Regulamin</a> oraz <a href="https://example.com/polityka" target="_blank">Politykę Prywatności</a>`,
        },
    },
    auth: {
        content: {
            description:
                'A professional kit that comes with ready-to-use MUI components developed with one common goal in mind, help you build faster & beautiful applications.',
        },
        login: {
            title: 'Log in',
            description: 'Nie masz jeszcze konta? <0>Zarejestruj się</0>',
            form: {
                labels: {
                    email: 'Email',
                    password: 'Hasło',
                },
            },
            buttons: {
                login: 'Login',
                resetPassword: 'Reset hasła',
            },
        },
        register: {
            title: 'Rejestracja',
            description: 'Posiadasz już konto? <0>Zaloguj się</0>',
            form: {
                labels: {
                    plan: 'Plan',
                    email: 'Email',
                    password: 'Hasło',
                    repeatPassword: 'Powtórz hasło',
                    privacyPolicy: `Zapoznałem się i akceptuję <a href="https://example.com/regulamin" target="_blank">Regulamin</a> oraz <a href="https://example.com/polityka" target="_blank">Politykę Prywatności</a>`,
                },
                planOptions: {
                    free: 'Free',
                },
            },
            buttons: {
                register: 'Zarejestruj się',
            },
        },
        resetPassword: {
            title: 'Reset hasła',
            description: 'Przypomniałeś sobie hasło? <0>Zaloguj się</0>',
            form: {
                labels: {
                    email: 'Email',
                },
            },
            buttons: {
                sendEmail: 'Wyślij link',
                back: 'Powrót',
            },
        },
        confirmRegistration: {
            title: 'Potwierdź rejestrację',
            description: 'Aby zakończyć rejestrację na platformie kliknij przycisk poniżej',
            buttons: {
                confirm: 'Potwierdź',
            },
        },
        changePassword: {
            title: 'Resetowanie hasła',
            description: 'Wprowadź nowe hasło.',
            form: {
                labels: {
                    password: 'Hasło',
                    repeatPassword: 'Powtórz hasło',
                },
            },
            buttons: {
                changePassword: 'Ustaw nowe hasło',
            },
        },
    },
    reservationsView: {
        title: 'Rezerwacje',
        table: {
            header: {
                contact: 'Kontakt',
                room: 'Sala',
                numberOfPeople: 'Liczba osób',
                time: 'Godzina',
                prepayment: 'Przedpłata',
                message: 'Wiadomość',
            },
            noData: 'Brak danych',
        },
        filters: {
            search: 'Znajdź rezerwację',
            room: 'Sala',
            date: 'Data',
        },
        btn: {
            print: 'Drukuj',
        },
    },
    restaurantForm: {
        form: 'Formularz',
        copySuccess: 'Skopiowano!',
        save: 'Zapisz',
        copyToClipboard: 'Skopiuj do schowka',
        restaurantDetailsCard: {
            title: 'Lokal',
            form: {
                labels: {
                    name: 'Nazwa',
                    address: 'Adres',
                    email: 'E-mail kontaktowy',
                    phone: 'Telefon kontaktowy',
                    privacyPolicy: 'Polityka prywatności',
                },
            },
        },
        openHoursCard: {
            title: 'Godziny otwarcia',
            from: 'Otwarcie',
            to: 'Zamknięcie',
            buttons: {
                edit: 'Edytuj',
                save: 'Zapisz',
                accept: 'Zatwierdź',
            },
            closed: 'Zamknięte',
            weekdays: {
                _0: 'Poniedziałek',
                _1: 'Wtorek',
                _2: 'Środa',
                _3: 'Czwartek',
                _4: 'Piątek',
                _5: 'Sobota',
                _6: 'Niedziela',
            },
        },
        reservationCard: {
            title: 'Rezerwacje',
            form: {
                labels: {
                    maxPeopleInReservation: 'Maksymalna liczba ludzi w wszystkich rezerwacjach',
                    maxPeopleInSingleReservation: 'Maksymalna liczba ludzi w pojedynczej rezerwacji',
                    minReservationHour: 'Min godzina rozpoczęcia rezerwacji',
                    maxReservationHour: 'Max godzina rozpoczęcia rezerwacji',
                    reservationInterval: 'Interwał rezerwacji',
                    prepaymentForReservations: 'Przedpłata za rezerwacje',
                    systemWillCharge: 'System pobierze płatność - liczba osób razy przedpłata za osobę.',
                    prepaymentPerPerson: 'Przedpłata za osobę',
                },
            },
        },
        roomDivisionCard: {
            title: 'Podział lokalu na sale',
            form: {
                labels: {
                    roomLayout: 'Plan lokalu',
                    addImage: 'Kliknij aby dodać lub przeciągnij i upuść',
                    imageFormat: 'PNG, WEBP, JPG',
                    imageSize: 'max 2Mb',
                    imageResolution: 'optymalnie 650x650px',
                    roomDivision: 'Podział na sale',
                    addRoom: 'Dodaj salę',
                },
            },
            roomList: {
                listHeaders: {
                    roomDivision: 'Podział na sale',
                    addRoomButton: 'Dodaj salę',
                },
                room_item: {
                    numberOfPeople: 'Liczba osób: {{count}} / {{maxCount}}',
                    reservationTime: 'Rezerwacja: {{hourStart}} - {{hourEnd}} / {{step}}min',
                    prepaidPerPerson: 'Przedpłata za osobę: {{amount}} {{currency}}',
                    edit: 'Edytuj',
                    delete: 'Usuń',
                },
                addRoom: {
                    title: 'Dodaj salę',
                    form: {
                        labels: {
                            name: 'Nazwa',
                            maxPeople: 'Maksymalna liczba osób w sali',
                            maxReservationPeople: 'Maksymalna liczba osób w rezerwacji',
                            minReservationHour: 'Min godzina rozpocecia rezerwacji',
                            maxReservationHour: 'Max godzina rozpocecia rezerwacji',
                            reservationInterval: 'Interwał rezerwacji',
                            prepaymentForReservation: 'Przedpłata za rezerwacje',
                            systemWillCharge: 'System pobierze płatność - liczba osób razy przedpłata za osobę.',
                            prepaymentPerPerson: 'Przedpłata za osobę',
                        },
                    },
                },
            },
        },
        formAppearanceCard: {
            title: 'Wygląd Formularza',
            form: {
                labels: {
                    color: 'Kolor głowny',
                    backgroundImage: 'Zdjęcie w tle',
                },
                removeImage: 'Usuń zdjęcie',
            },
        },
        formHeaderCard: {
            title: 'Nagłówek Formularza',
            form: {
                labels: {
                    avatar: 'Avatar',
                    name: 'Tytuł',
                    shortDescription: 'Krótki opis',
                    initialDescription: 'Informacja na start',
                },
                removeImage: 'Usuń zdjęcie',
            },
        },
    },
    accountView: {
        title: 'Konto',
        profile: {
            title: 'Profil',
            email: 'E-mail',
            plan: 'Plan',
            plan_expiration: 'Ważność planu',
            stripe: 'Stripe',
            onboarding: 'Przejdź OnBoarding',
            stripeUrl: 'Przejdź do Stripe',
        },
        passwordChange: {
            title: 'Zmień hasło',
            form: {
                labels: {
                    oldPassword: 'Stare hasło',
                    newPassword: 'Nowe hasło',
                    repeatPassword: 'Powtórz nowe hasło',
                },
            },
            btn_save: 'Zapisz',
        },
        settings: {
            title: 'Ustawienia',
            form: {
                labels: {
                    language: 'Język',
                    languageList: {
                        pl: 'Polski',
                        en: 'Angielski',
                        uk: 'Ukraiński',
                    },
                    currency: 'Waluta',
                    currencyList: {
                        pln: 'PLN',
                        eur: 'EUR',
                        usd: 'USD',
                    },
                },
            },
        },
        deleteAccount: {
            title: 'Usuń konto',
            description: 'Usuń konto oraz wszystkie Twoje dane. Operacja jest nieodwracalna.',
            btnDelete: 'Usuń konto',
            deleteModal: {
                title: 'Usuń konto',
                subtitle: 'Usuń swoje konto oraz wszystkie dane z nim związane.',
                warning: 'Ta operacja jest nieodwracalna.',
                cannotBeDeleted: 'Nie możesz teraz usunąć konta.',
                buttons: {
                    deleteAccount: 'Usuń konto',
                    closeModal: 'Zamknij',
                },
            },
        },
        alerts: {
            changeLocaleSuccess: 'Język został zmieniony',
            changePasswordSuccess: 'Hasło zostało zmienione',
            profileDeleted: 'Konto zostało usunięte.',
            changeEmailSuccess: 'Adres e-mail został zmieniony',
        },
    },
    subscriptionsView: {
        title: 'Rezerwacje z klasą. Go Premium!',
        description: 'Usprawnij proces rezerwacji w swoim lokalu dzięki dodatkowym funkcjonalnościom Premium.',
    },
    footer: {
        copyright: `Reservation Spot {{date}} © All right reserved`,
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony',
    },
    alerts: {
        restaurantForm: {
            updated: 'Pomyślnie zaktualizowano dane.',
            created: 'Pomyślnie utworzono restaruację.',
        },
        auth: {
            registrationSuccess: 'Wysłaliśmy mail z linkiem aktywacyjnym. Sprawdź pocztę.',
            confirmRegistrationSuccess: 'Pomyślnie potwierdzono rejestrację. Zaloguj się.',
            invalidConfirmRegistrationUrl: 'Nieprawidłowy link potwierdzający rejestrację.',
            invalidResetPasswordUrl: 'Nieprawidłowy link do zmiany hasła.',
            sendResetPasswordMailSuccess: 'Wysłaliśmy mail z linkiem do resetu hasła. Sprawdź pocztę.',
            confirmResetPasswordSuccess: 'Hasło zostało zmienione. Zaloguj się.',
        },
        warning: 'Uwaga!',
        error: 'Błąd!',
        success: 'Sukces!',
        info: 'Informacja',
        authError: 'Nazwa użytkownika lub hasło są nieprawidłowe.',
        no_access_error: 'Nie masz uprawnień do przeglądania tej strony.',
        wrong_account_error: 'Nie możesz się zalogować do tej aplikacji za pomocą tego konta.',
        base_error: 'Wystąpił błąd, skontaktuj się z administratorem.',
        login_error: 'Wystąpił błąd przy logowaniu, spróbuj ponownie.',
        try_again: 'Spróbuj ponownie',
        unknown: 'Wystąpił nieznany błąd.',
        access_denied: 'Nie masz uprawnień.',
        payment_method_attached: 'Metoda płatności została pomyślnie dodana.',
        payment_method_deleted: 'Metoda płatności została usunięta.',
        account_not_confirmed: 'Konto nie zostało potwierdzone.',
        account_not_active: 'Konto nie jest aktywne.',
        account_deleted: 'Konto zostało usunięte.',
        account_is_invitation: 'Nieprawidłowe dane.',
        invalid_or_expired_token: 'Twój token wygasł lub jest nieprawidłowy.',
        expiredTokenLogout: 'Twoja sesja wygasła. Zostałeś wylogowany.',
        passwordChanged: 'Hasło zostało zmienione',
        bad_password: 'Niepoprawne hasło',
        copied: 'Skopiowano!',
        copyFailed: 'Kopiowanie nie powiodło się',
    },
    validation: {
        required: 'To pole jest wymagane',
        invalidEmail: 'Nieprawidłowy adres e-mail',
        invalidPassword: 'Nieprawidłowe hasło',
        invalidRepeatPassword: 'Hasła nie są takie same',
        invalidPhone: 'Nieprawidłowy numer telefonu',
        invalidUrl: 'Nieprawidłowy adres URL',
        invalidDate: 'Nieprawidłowa data',
        invalidImage: 'Nieprawidłowy format obrazu, dozwolone formaty to: {{extensions}}',
        invalidImageSize: 'Zbyt duży rozmiar obrazu, maksymalny rozmiar to {{size}} MB',
        invalidImageResolution: 'Zła rozdzielczość obrazu',
        invalidPlan: 'Nieprawidłowy plan',
        passwordPattern: 'Zbyt słabe hasło',
        passwordsMustMatch: 'Hasła muszą być takie same',
        mustAcceptPrivacyPolicy: 'Musisz zaakceptować regulamin',
    },
    fileUpload: {
        clickToAdd: 'Kliknij aby dodać',
        or: ' lub',
        dragAndDrop: ' przeciągnij i upuść',
        fileExtensions: 'PNG, WEBP, JPG',
        fileSize: 'max {{size}} MB',
        fileResolution: 'optymalnie {{resolution}} px',
        removeImage: 'Usuń zdjęcie',
    },
};

export default en;
