import {LanguageLocale} from '../constants/locales';
import enLocale from 'date-fns/locale/en-US';
import {useTranslation} from 'react-i18next';

export const transformLocaleToLocaleType = (localeType: string): LanguageLocale => {
    const extracted = localeType.split('_')[0];
    return extracted as LanguageLocale;
};

export const customLocaleTranslation = () => {
    const {t} = useTranslation();
    const customMonths = [
        `${t('month.january')}`,
        `${t('month.february')}`,
        `${t('month.march')}`,
        `${t('month.april')}`,
        `${t('month.may')}`,
        `${t('month.june')}`,
        `${t('month.july')}`,
        `${t('month.august')}`,
        `${t('month.september')}`,
        `${t('month.october')}`,
        `${t('month.november')}`,
        `${t('month.december')}`,
    ];
    return {
        ...enLocale,
        localize: {
            ...enLocale.localize,
            month: (n) => customMonths[n],
        },
    };
};
