import { ErrorMessage, Field, useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Subscription, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { createMediaObjectAPI } from '../../../api/mediaObject/createMediaObjectAPI';
import DefaultAvatar from '../../../assets/icons/default-avatar';
import { FormikFieldConfig, IFileUploadListElement } from '../../../model/form';
import { authTokenSelector } from '../../../store/selectors/authSelectors';
import AuthorizedImage from '../../AuthorizedImage';
import { FieldProps } from 'formik';
import Loader from '../../Loader';
import { ImageType, LoaderType } from '../../../model/common';
import Translation from '../../Translation';

const FileFormControl: React.FC<FormikFieldConfig> = ({
    name,
    label,
    className,
    fileUploadRequirements,
}) => {
    const [mediaItem, setMediaItem] = useState<IFileUploadListElement | null>(null);
    const { setFieldValue, setFieldTouched, setFieldError } = useFormikContext();
    const [error, setError] = useState<string>('');
    const [field, meta, helpers] = useField(name);
    const acceptedExtensions = fileUploadRequirements.extensions;
    const maxFileSize = fileUploadRequirements?.size ? fileUploadRequirements.size * 1024 * 1024 : 0;
    const { t } = useTranslation(),
        authToken: string = useSelector(authTokenSelector),
        [isProcessing, setIsProcessing] = useState<boolean>(false),
        subscriptions: Subscription[] = [];
    useEffect(() => {
        if (field.value) {
            const mediaItemFromUrl = {
                name: field.value,
                fileKey: field.value,
                url: field.value,
            };
            setMediaItem(mediaItemFromUrl);
        }
        return () => {
            subscriptions.forEach((subscription) => subscription.unsubscribe());
        };
    }, [field.value]);

    const removeImage = () => {
        setMediaItem(null);
        setFieldValue(name, '');

    };   
    const fileHandleChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        if (!e.target.files) return;
        const files: File[] = Array.from(e.target.files);
        setError('');
        setIsProcessing(true);
        if (files.length > 0) {
            const documentImage = files[0];

            if (documentImage) {
                if (acceptedExtensions.length > 0 && !acceptedExtensions.includes(documentImage.type as ImageType)) {
                    setFieldTouched(name, true); 
                    const acceptedExtensionsString = fileUploadRequirements.extensions.map((extension: ImageType) => extension.split('/')[1]).join(', ');
                    const errorMessage = t('validation.invalidImage', { extensions: acceptedExtensionsString });
                    setFieldError(name, errorMessage);
                    setError(errorMessage);
                    setIsProcessing(false);
                    return;
                }
        
                if (maxFileSize > 0 && documentImage.size > maxFileSize) {
                    setFieldTouched(name, true); 
                    const errorMessage = t('validation.invalidImageSize', { size: fileUploadRequirements.size });
                    setFieldError(name, errorMessage);
                    setError(errorMessage);
                    setIsProcessing(false);
                    return;
                }
            }
            const documentName = documentImage.name;
            const formData = new FormData();
            formData.append('file', documentImage);
            formData.append('public', 'true');
            subscriptions.push(
                createMediaObjectAPI(authToken, formData)
                    .pipe(
                        tap((resp: any) => {
                            if (resp.response) {
                                const mediaItem = {
                                    name: documentName ? documentName : resp.response?.fileName,
                                    fileKey: resp.response.id,
                                    url: resp.response.contentUrl ? resp.response.contentUrl : resp.response.fileUrls.original,
                                };
                                setMediaItem(mediaItem);
                                setFieldValue(name, resp.response.id ? resp.response.id : mediaItem.fileKey);
                            }
                            setIsProcessing(false);
                        }),
                        catchError((error) => {
                            setIsProcessing(false);
                            return of(...error);
                        })
                    )
                    .subscribe()
            );
        }
    };

    return (
        <div className={`form-control file-upload ${className} ${meta.touched && error ? 'invalid' : ''} `} key={`file-input-wrapper-${name}`}>

            <div className="form-label" >
                {t(label)}
            </div>

            {mediaItem && mediaItem.fileKey &&
                <div className="image-preview-wrapper">
                    <AuthorizedImage url={mediaItem?.url} alt={mediaItem?.name} default={null} />
                    <button className="remove-image-button" onClick={() => removeImage()}><Translation text="fileUpload.removeImage" /></button>
                </div>
            }
            <div className={`file-upload-input ${className}`}>
                <Field name={field.name}>
                    {({ field, form }: FieldProps) => (
                        <div className="file-upload-field">
                            <input
                                type="file"
                                {...field}
                                id={name}
                                value={''}
                                className="form-input file-input"
                                onChange={(e) => fileHandleChange(e, name)}
                            />
                            <label htmlFor={name} className="file-upload-label">
                                <DefaultAvatar />
                                <div className="file-upload-description">
                                    <div className="file-upload-title">
                                        <span className="bold underlined">
                                            <Translation text="fileUpload.clickToAdd" />
                                        </span>
                                        <Translation text="fileUpload.or" />
                                        <span className="bold">
                                            <Translation text="fileUpload.dragAndDrop" />
                                        </span>
                                    </div>
                                    {fileUploadRequirements && <>
                                        <ul className="file-input-requirement-list">
                                            {fileUploadRequirements.extensions &&
                                                <li className="input-requirement-item" key={`file-input-wrapper-requirement-extension`}><Translation text="fileUpload.fileExtensions" config={{ extensions: fileUploadRequirements.extensions }} /></li>
                                            }
                                            {fileUploadRequirements.size &&
                                                <li className="input-requirement-item" key={`file-input-wrapper-requirement-size`}><Translation text="fileUpload.fileSize" config={{ size: fileUploadRequirements.size }} /></li>
                                            }
                                            {fileUploadRequirements.width && fileUploadRequirements.height &&
                                                <li className="input-requirement-item" key={`file-input-wrapper-requirement-resolution`}><Translation text="fileUpload.fileResolution" config={{ resolution: `${fileUploadRequirements.width} x ${fileUploadRequirements.height}` }} /></li>
                                            }
                                        </ul>
                                    </>
                                    }
                                </div>
                            </label>
                        </div>
                    )}
                </Field>
            </div>
            {meta.touched && error && 
                <div key={`error}`} className="error-message">{error}</div>
            }
            <Loader type={LoaderType.Local} showLoader={isProcessing} />
        </div>
    );
};
export default FileFormControl;
