import React from 'react';
import {Translation} from 'reservation-common-web';
import ProfileCard from './ProfileCard';
import ChangePasswordCard from './ChangePasswordCard';
import SettingsCard from './SettingsCard';
import ProfileDeleteCard from './ProfileDeleteCard';

const Account: React.FC = () => {
    return (
        <div className="row">
            <div className="col-2">
                <div className="header">
                    <h2 className="title">
                        <Translation text="accountView.title" />
                    </h2>
                </div>
                <div className="content">
                    <ProfileCard />
                    <ChangePasswordCard />
                    <SettingsCard />
                    <ProfileDeleteCard />
                </div>
            </div>
            <div className="col-1" />
        </div>
    );
};

export default Account;
