import type {SVGProps} from 'react';

const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.3 6.3V4.41C6.3 3.65393 6.3 3.27589 6.44714 2.98711C6.57657 2.73309 6.78309 2.52657 7.03711 2.39714C7.32589 2.25 7.70393 2.25 8.46 2.25H13.59C14.3461 2.25 14.7241 2.25 15.0129 2.39714C15.2669 2.52657 15.4734 2.73309 15.6029 2.98711C15.75 3.27589 15.75 3.65393 15.75 4.41V9.54C15.75 10.2961 15.75 10.6741 15.6029 10.9629C15.4734 11.2169 15.2669 11.4234 15.0129 11.5529C14.7241 11.7 14.3461 11.7 13.59 11.7H11.7M4.41 15.75H9.54C10.2961 15.75 10.6741 15.75 10.9629 15.6029C11.2169 15.4734 11.4234 15.2669 11.5529 15.0129C11.7 14.7241 11.7 14.3461 11.7 13.59V8.46C11.7 7.70393 11.7 7.32589 11.5529 7.03711C11.4234 6.78309 11.2169 6.57657 10.9629 6.44714C10.6741 6.3 10.2961 6.3 9.54 6.3H4.41C3.65393 6.3 3.27589 6.3 2.98711 6.44714C2.73309 6.57657 2.52657 6.78309 2.39714 7.03711C2.25 7.32589 2.25 7.70393 2.25 8.46V13.59C2.25 14.3461 2.25 14.7241 2.39714 15.0129C2.52657 15.2669 2.73309 15.4734 2.98711 15.6029C3.27589 15.75 3.65393 15.75 4.41 15.75Z"
            stroke="#6C737F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CopyIcon;
