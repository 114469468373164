import React from 'react';
import {Field, useField, ErrorMessage} from 'formik';
import {FormikFieldConfig} from '../../../model/form';

const TextField: React.FC<FormikFieldConfig> = ({name, label, className, isRequired, placeholder, type, disabled, autofocus}) => {
    const [field, meta] = useField(name);

    return (
        <div className={`form-control ${type} ${meta.touched && meta.error ? 'invalid' : ''}`} key={name}>
            <Field
                id={field.name}
                name={field.name}
                type={type}
                required={isRequired}
                className={`form-input ${field.value ? 'filled' : ''}  ${placeholder ? 'placeholder-present' : ''}`}
                placeholder={placeholder ? placeholder : ''}
                disabled={disabled}
                autoFocus={autofocus}
            />

            <label htmlFor={name} className="form-label">
                {label} {isRequired ? <span className="label-required">*</span> : ''}
            </label>
            <ErrorMessage name={name} component="div" className="error-message" />
        </div>
    );
};

export default TextField;
