import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import CopyIcon from '../../../../assets/icons/copy';
import {IRestaurantOutput, accountRestaurantSelector} from 'reservation-common-web';

const WidgetLinkSnippet: React.FC = () => {
    const {t} = useTranslation();
    const [isCopied, setIsCopied] = useState(false);
    const accountRestaurant: IRestaurantOutput | null = useSelector(accountRestaurantSelector);
    const slugUrl = `${process.env.REACT_APP_RESTAURANT_URL}/${accountRestaurant?.slug}`;
    const handleCopyClick = () => {
        navigator.clipboard
            .writeText(slugUrl)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
            })
            .catch((err) => {
                console.error(t('alerts.copyFailed'), err);
            });
    };

    const renderSnippet = () => {
        if (!accountRestaurant) return <div />;

        return (
            <div className="slug-container">
                <span className="slug-input" aria-label="Reservation URL">
                    {slugUrl}
                </span>
                <button onClick={handleCopyClick} className="copy-button" aria-label={`${t('restaurantForm.copyToClipboard')}`}>
                    {isCopied ? (
                        <span className="copied-text">{t('restaurantForm.copySuccess')}</span>
                    ) : (
                        <span className="copy-icon">
                            <CopyIcon />
                            <span className="sr-only">{t('restaurantForm.copy')}</span>
                        </span>
                    )}
                </button>
            </div>
        );
    };

    return renderSnippet();
};

export default WidgetLinkSnippet;
