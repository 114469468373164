import React from 'react';
import CustomCard from '../../shared/CustomCard';
import ProfileItem from './ProfileItem';
import {useSelector} from 'react-redux';
import {accountUserNameSelector, Translation} from 'reservation-common-web';
import PremiumBadge from '../../shared/PremiumBadge';

const ProfileTab: React.FC = () => {
    const username: string | undefined = useSelector(accountUserNameSelector);

    const renderSubscriptionDetails = () => {
        const isPremium = false;
        return isPremium ? (
            <p>Premium</p>
        ) : (
            <>
                <p className="value plan-value">Free</p>
                <PremiumBadge />
            </>
        );
    };

    const renderStripeDetails = () => {
        return (
            <>
                <button className="btn-primary-outline">
                    <Translation text="accountView.profile.onboarding" />
                </button>
                <button className="btn-primary-outline">
                    <Translation text="accountView.profile.stripeUrl" />
                </button>
            </>
        );
    };

    return (
        <CustomCard
            title="accountView.profile.title"
            content={
                <div className="profile-details">
                    <ProfileItem label="accountView.profile.email">
                        <p className="value">{username}</p>
                    </ProfileItem>

                    <ProfileItem label="accountView.profile.plan">{renderSubscriptionDetails()}</ProfileItem>

                    <ProfileItem label="accountView.profile.plan_expiration">
                        <p className="value">21.09.2024</p>
                    </ProfileItem>

                    <ProfileItem label="accountView.profile.stripe">{renderStripeDetails()}</ProfileItem>
                </div>
            }
        />
    );
};

export default ProfileTab;
