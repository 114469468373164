import {PayloadAction} from '@reduxjs/toolkit';
import {
    PAGINATION_ITEMS_PER_PAGE,
    createBaseReducerSlice,
    isSameValue,
    IModelApiResponseViewObject,
    IReservationOutput,
} from 'reservation-common-web';

export interface IReservationFilters {
    'date[before]': string | null;
    'date[after]': string | null;
    search: string | null;
    'room.id[]': string[] | null;
}

export interface IChangeReservationFilters {
    reservationsFilters: IReservationFilters;
}

export interface ISetMetadata {
    metadata: IModelApiResponseViewObject | null;
}

export interface IPaginationParams {
    itemsPerPage: number;
    page: number;
}

export interface IReservationsState {
    reservations: IReservationOutput[] | null;
    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;
    filters: IReservationFilters;
    pagination: IPaginationParams;
    metadata: IModelApiResponseViewObject | null;
}

export interface ISetReservations {
    reservations: IReservationOutput[] | null;
}

const initialState: IReservationsState = {
    reservations: null,
    isLoading: false,
    isInitialized: false,
    error: null,
    filters: {
        'date[after]': null,
        'date[before]': null,
        search: null,
        'room.id[]': null,
    },
    pagination: {
        page: 1,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    },
    metadata: null,
};

const reservationsSlice = createBaseReducerSlice({
    name: 'reservationsSlice',
    initialState: initialState,
    reducers: {
        getReservations: (state: IReservationsState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        applyReservationsFilters: (state: IReservationsState) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        setMetadata: (state: IReservationsState, action: PayloadAction<ISetMetadata>) => {
            state.metadata = action.payload.metadata;
        },
        changeFilters: {
            reducer: (state: IReservationsState, action: PayloadAction<IChangeReservationFilters>) => {
                if (isSameValue(action.payload.reservationsFilters, state.filters)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isLoading: true,
                    filters: action.payload.reservationsFilters,
                };
            },
            prepare: (filters: IReservationFilters) => {
                return {
                    payload: {reservationsFilters: filters},
                };
            },
        },
        setReservations: {
            reducer: (state: IReservationsState, action: PayloadAction<ISetReservations>) => {
                return {
                    ...state,
                    isLoading: false,
                    reservations: action.payload.reservations,
                };
            },
            prepare: (reservations: IReservationOutput[] | null) => {
                return {
                    payload: {
                        reservations: reservations,
                    },
                };
            },
        },
        changePagination: (state: IReservationsState, action: PayloadAction<IPaginationParams>) => {
            state.pagination = action.payload;
            state.isLoading = true;
        },
        setLoading: (state: IReservationsState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setInitialized: (state: IReservationsState, action: PayloadAction<boolean>) => {
            state.isInitialized = action.payload;
        },
        setError: (state: IReservationsState, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        resetToInitialReservationsState: () => {
            return {...initialState};
        },
    },
});

export const {
    getReservations,
    applyReservationsFilters,
    setMetadata,
    changeFilters,
    setReservations,
    changePagination,
    setInitialized,
    setLoading,
    setError,
    resetToInitialReservationsState,
} = reservationsSlice.actions;

export default reservationsSlice.reducer;
