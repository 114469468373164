import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {IRestaurantInput, IRestaurantOutput} from '../../model/restaurant';
import {reservationsAPI} from '../provider/reservationsAPI';
import {IApiSingleResponseBase} from '../../model/base';

export const createRestaurantAPI: ApiOperationResponseFunction<IRestaurantOutput> = (
    authToken: string,
    payload: IRestaurantInput
): Observable<IApiSingleResponseBase<IRestaurantOutput>> => {
    return reservationsAPI.post('api/restaurants', payload, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
