import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IRestaurantState} from '../reducers/restaurantSlice';

export const selectRestaurantSlice = (state: RootState) => {
    return state.restaurant;
};

export const restaurantSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.restaurant);

export const restaurantConfigurationSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.restaurant?.configuration || null
);

export const restaurantRoomsSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.restaurant?.rooms || null
);

export const restaurantConfigurationOpenHoursSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.restaurant?.configuration?.openHours || null
);

export const isOpenHoursValidSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.isOpenHoursValid);

export const isRestaurantDetailsValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isRestaurantDetailsValid
);
export const isRestaurantHeadingValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isRestaurantHeadingValid
);
export const isRestaurantRoomsValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isRestaurantRoomsValid
);
export const isRestaurantAppearanceValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) => state.isFormAppearanceValid
);
export const isRestaurantFormValidSelector = createSelector(
    [selectRestaurantSlice],
    (state: IRestaurantState) =>
        state.isRestaurantDetailsValid && state.isRestaurantHeadingValid && state.isRestaurantRoomsValid && state.isFormAppearanceValid
);

export const isRestaurantLoadingSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.isLoading);

export const isRestaurantInitializedSelector = createSelector([selectRestaurantSlice], (state: IRestaurantState) => state.isInitialized);
