import {createSelector} from '@reduxjs/toolkit';
import {AlertObject, IAlertState} from '../reducers/alertSlice';

export const selectAlert = (state: any): IAlertState => {
    return state.alert;
};

export const alertsSelector = createSelector([selectAlert], (state: IAlertState) => (state.alerts ? state.alerts : []));

export const disabledAlertsSelector = createSelector([selectAlert], (state: IAlertState) => state.alertsDisabled);
