import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {reservationsAPI} from '../provider/reservationsAPI';
import { IChangeUserPasswordInput } from '../../model/user';

export function createChangePasswordAPI(payload: IChangeUserPasswordInput, accessToken: string): Observable<any> {
    return reservationsAPI.post(
        'api/users/change_password',
        payload,
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        }
    );
}
