import {Observable} from 'rxjs';
import {reservationsAPI} from '../provider/reservationsAPI';
import {IApiSingleResponseBase} from '../../model/base';
import {RestQueryParams} from '../base/queryParams';

export function deleteAccountAPI(authToken: string, id: string): Observable<IApiSingleResponseBase<undefined>> {
    return reservationsAPI.delete(`api/accounts/${id}`, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
