import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRestaurantOutput, accountRestaurantSelector, Translation} from 'reservation-common-web';
import {createRestaurant, updateRestaurant} from '../../../store/reducers/restaurantSlice';
import {isRestaurantFormValidSelector} from '../../../store/selectors/restaurantSelector';

const RestaurantFormFooter: React.FC = () => {
    const dispatch = useDispatch();
    const accountRestaurant: IRestaurantOutput | null = useSelector(accountRestaurantSelector);
    const isFormValid = useSelector(isRestaurantFormValidSelector);

    const handleSubmit = () => {
        const action = accountRestaurant ? updateRestaurant() : createRestaurant();
        dispatch(action);
    };

    return (
        <div className="footer">
            <button className="btn btn-primary" type="submit" onClick={handleSubmit} disabled={!isFormValid}>
                <Translation text="restaurantForm.save" />
            </button>
        </div>
    );
};

export default RestaurantFormFooter;
