import React from 'react';
import {BasicModal, Translation} from 'reservation-common-web';

interface DeleteAccountDialogProps {
    isModalShown: boolean;
    toggleModal: () => void;
    handleConfirm: () => void;
}

const DeleteProfileModal: React.FC<DeleteAccountDialogProps> = ({isModalShown, toggleModal, handleConfirm}) => {
    return (
        <BasicModal isModalShown={isModalShown} closeModal={toggleModal}>
            <BasicModal.Header>
                <h2>
                    <Translation text="accountView.deleteAccount.deleteModal.title" />
                </h2>
            </BasicModal.Header>
            <BasicModal.Body>
                <Translation text="accountView.deleteAccount.deleteModal.subtitle" />
                <Translation text="accountView.deleteAccount.deleteModal.warning" />
            </BasicModal.Body>
            <BasicModal.Footer>
                <button onClick={toggleModal} className="btn-primary-outline">
                    <Translation text="accountView.deleteAccount.deleteModal.buttons.closeModal" />
                </button>
                <button onClick={handleConfirm} className="btn-primary">
                    <Translation text="accountView.deleteAccount.deleteModal.buttons.deleteAccount" />
                </button>
            </BasicModal.Footer>
        </BasicModal>
    );
};

export default DeleteProfileModal;
