import {PayloadAction} from '@reduxjs/toolkit';
import {createBaseReducerSlice, IBaseReducerState, IOpenHoursInput, IRestaurantOutput, IRoomOutput} from 'reservation-common-web';

export interface IRestaurantState extends IBaseReducerState {
    restaurant: IRestaurantOutput | null;

    isLoading: boolean;
    isInitialized: boolean;
    error: string | null;

    isCreateRoomModalOpen: boolean;
    isRestaurantDetailsValid: boolean;
    isRestaurantRoomsValid: boolean;
    isRestaurantHeadingValid: boolean;
    isOpenHoursValid: boolean;
    isFormAppearanceValid: boolean;
}

export interface ISetRestaurant {
    restaurant: IRestaurantOutput | null;
}

export interface ISetRestaurantDetails {
    name: string;
    address: string;
    email: string;
    phone: string | undefined;
    privacyPolicy: string;
}

export interface ISetFormAppearance {
    color: string;
    backgroundId: string;
}

export interface ISetFormHeader {
    name: string;
    avatarId: string;
    shortDescription: string;
    initialDescription: string;
}

export interface ISetOpenHours {
    openHours: IOpenHoursInput;
}

const initialState: IRestaurantState = {
    restaurant: null,

    isLoading: false,
    isInitialized: false,
    error: null,

    isCreateRoomModalOpen: false,
    isRestaurantDetailsValid: false,
    isRestaurantHeadingValid: false,
    isRestaurantRoomsValid: true,
    // change validitity of rooms when isPremium is implemented - then you will have possibility to edit that
    isOpenHoursValid: false,
    isFormAppearanceValid: false,
};

const restaurantSlice = createBaseReducerSlice({
    name: 'restaurantSlice',
    initialState: initialState,
    reducers: {
        initRestaurant: {
            reducer: (state: IRestaurantState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
        },
        setRestaurant: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetRestaurant>) => {
                return {
                    ...state,
                    restaurant: action.payload.restaurant,
                };
            },
            prepare(restaurant: IRestaurantOutput) {
                return {
                    payload: {restaurant: restaurant},
                };
            },
        },
        setRestaurantDetails: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetRestaurantDetails>) => {
                return {
                    ...state,
                    restaurant: {
                        ...state.restaurant,
                        name: action.payload.name,
                        address: action.payload.address,
                        email: action.payload.email,
                        phone: action.payload.phone,
                        configuration: {
                            ...state.restaurant?.configuration,
                            privacyPolicy: action.payload.privacyPolicy,
                        },
                    },
                };
            },
            prepare(restaurantDetails: ISetRestaurantDetails) {
                return {
                    payload: restaurantDetails,
                };
            },
        },
        setIsRestaurantDetailsValid: {
            reducer: (state: IRestaurantState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isRestaurantDetailsValid: action.payload,
                };
            },
            prepare(isRestaurantDetailsValid: boolean) {
                return {
                    payload: isRestaurantDetailsValid,
                };
            },
        },
        setOpenHours: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetOpenHours>) => {
                return {
                    ...state,
                    restaurant: {
                        ...state.restaurant,
                        configuration: {
                            ...state.restaurant?.configuration,
                            openHours: action.payload.openHours,
                        },
                    },
                };
            },
            prepare(openHours: ISetOpenHours) {
                return {
                    payload: {
                        openHours: openHours,
                    },
                };
            },
        },
        setIsOpenHoursValid: {
            reducer: (state: IRestaurantState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isOpenHoursValid: action.payload,
                };
            },
            prepare(isOpenHoursValid: boolean) {
                return {
                    payload: isOpenHoursValid,
                };
            },
        },
        setRoomDivision: {
            reducer: (state: IRestaurantState, action: PayloadAction<IRoomOutput[]>) => {
                return {
                    ...state,
                    restaurant: {
                        ...state.restaurant,
                        rooms: action.payload,
                    },
                };
            },
            prepare(rooms: IRoomOutput[]) {
                return {
                    payload: rooms,
                };
            },
        },
        setFormAppearance: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetFormAppearance>) => {
                return {
                    ...state,
                    restaurant: {
                        ...state.restaurant,
                        configuration: {
                            ...state.restaurant?.configuration,
                            color: action.payload.color,
                            background: {
                                ...state.restaurant?.configuration.background,
                                id: action.payload.backgroundId,
                            },
                        },
                    },
                };
            },
            prepare(formAppearance: ISetFormAppearance) {
                return {
                    payload: {
                        color: formAppearance.color,
                        backgroundId: formAppearance.backgroundId,
                    },
                };
            },
        },
        setIsFormAppearanceValid: {
            reducer: (state: IRestaurantState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isFormAppearanceValid: action.payload,
                };
            },
            prepare(isFormAppearanceValid: boolean) {
                return {
                    payload: isFormAppearanceValid,
                };
            },
        },
        setFormHeader: {
            reducer: (state: IRestaurantState, action: PayloadAction<ISetFormHeader>) => {
                return {
                    ...state,
                    restaurant: {
                        ...state.restaurant,
                        configuration: {
                            ...state.restaurant?.configuration,
                            name: action.payload.name,
                            shortDescription: action.payload.shortDescription,
                            initialDescription: action.payload.initialDescription,
                            avatar: {
                                ...state.restaurant?.configuration.avatar,
                                id: action.payload.avatarId,
                            },
                        },
                    },
                };
            },
            prepare(restaurantHeading: ISetFormHeader) {
                return {
                    payload: {
                        avatarId: restaurantHeading.avatarId,
                        name: restaurantHeading.name,
                        shortDescription: restaurantHeading.shortDescription,
                        initialDescription: restaurantHeading.initialDescription,
                    },
                };
            },
        },
        setIsFormHeaderValid: {
            reducer: (state: IRestaurantState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isRestaurantHeadingValid: action.payload,
                };
            },
            prepare(isRestaurantHeadingValid: boolean) {
                return {
                    payload: isRestaurantHeadingValid,
                };
            },
        },
        createRestaurant: {
            reducer: (state: IRestaurantState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
        },
        updateRestaurant: {
            reducer: (state: IRestaurantState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
        },
        deleteRestaurant: {
            reducer: (state: IRestaurantState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(restaurantId: string) {
                return {
                    payload: {restaurantId},
                };
            },
        },
        setInitialized: (state: IRestaurantState, action: PayloadAction<boolean>) => {
            state.isInitialized = action.payload;
        },
        setLoading: (state: IRestaurantState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state: IRestaurantState, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const {
    initRestaurant,
    setRestaurant,

    updateRestaurant,
    createRestaurant,
    deleteRestaurant,

    setLoading,
    setError,
    setInitialized,

    setRestaurantDetails,
    setFormAppearance,
    setRoomDivision,
    setFormHeader,
    setOpenHours,

    setIsRestaurantDetailsValid,
    setIsFormAppearanceValid,
    setIsFormHeaderValid,
    setIsOpenHoursValid,
} = restaurantSlice.actions;
export default restaurantSlice.reducer;
