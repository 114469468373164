import {IApiError} from '../model/auth';
import {AlertType} from '../model/common';

export const handleErrorMessage = (error: IApiError | string): string => {
    if (typeof error === 'string') {
        return error;
    }

    const {message, 'hydra:description': hydraDescription, 'i18n:messageCode': i18nMessageCode} = error?.response || {};
    const messageLocalizationMap: Record<string, string> = {
        'Invalid credentials.': 'alerts.authError',
        'Nieprawidłowe dane.': 'alerts.authError',
        'Your account is not confirmed.': 'alerts.account_not_confirmed',
        'Konto nie zostało potwierdzone.': 'alerts.account_not_confirmed',
        'Konto nie jest aktywne.': 'alerts.account_not_active',
        'Konto zostało usunięte.': 'alerts.account_deleted',
        'Konto jest zaproszeniem.': 'alerts.account_is_invitation',
    };

    if (message && messageLocalizationMap[message]) {
        return messageLocalizationMap[message];
    }

    if (i18nMessageCode) {
        return i18nMessageCode;
    }

    if (hydraDescription) {
        if (hydraDescription.includes('Token not found')) {
            return 'alerts.auth.invalidConfirmRegistrationURL';
        }
        if (hydraDescription.includes('Access Denied')) {
            return 'alerts.access_denied';
        }
    }

    return message || hydraDescription || 'alerts.base_error';
};
export const handleApiError = (error: IApiError | string): IApiError => ({message: handleErrorMessage(error), type: AlertType.WARNING});
