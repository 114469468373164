import {PayloadAction} from '@reduxjs/toolkit';
import createBaseReducerSlice from './baseSlice';

export enum MemberOperationType {
    //common
    LOGOUT = 'logout',
}

export interface IModalState {
    isOpen: boolean;
    operationType: MemberOperationType | null;
}

export interface IOpenModal {
    isOpen: boolean;
    operationType: MemberOperationType | null;
}
const initialState: IModalState = {
    isOpen: false,
    operationType: null,
};

const modalSlice = createBaseReducerSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        openModal: {
            reducer: (state: IModalState, action: PayloadAction<IOpenModal>) => {
                return {
                    ...state,
                    isOpen: true,
                    operationType: action.payload.operationType,
                };
            },
            prepare: (operationType: MemberOperationType | null) => {
                return {
                    payload: {
                        operationType: operationType,
                    },
                };
            },
        },
        closeModal: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {openModal, closeModal} = modalSlice.actions;
export default modalSlice.reducer;
