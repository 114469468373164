import React from 'react';
import {Translation} from 'reservation-common-web';
import {NavLink} from 'react-router-dom';
import RocketIcon from '../../../assets/icons/rocket';

interface IPremiumBadgeProps {}

const PremiumBadge: React.FC<IPremiumBadgeProps> = () => {
    return (
        <NavLink to="/panel/subscriptions" className={'premium-badge'}>
            <RocketIcon />
            <Translation text="premium_badge.label" />
        </NavLink>
    );
};

export default PremiumBadge;
