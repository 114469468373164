import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {ApiOperationResponseFunction} from '../base/apiConnectionInfrastructure';
import {IReservationCreateInput, IReservationCreateOutput} from '../../model/restaurant';
import {IApiSingleResponseBase} from '../../model/base';
import {reservationsAPI} from '../provider/reservationsAPI';

export const createReservationAPI: ApiOperationResponseFunction<IReservationCreateOutput> = (
    authToken: string,
    payload: IReservationCreateInput
): Observable<IApiSingleResponseBase<IReservationCreateOutput>> => {
    return reservationsAPI.post('api/reservations', payload, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
};
