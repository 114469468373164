import {Observable} from 'rxjs';
import {IAccountMeOutput, IAccountProfileInput} from '../../model/account';
import {IApiSingleResponseBase} from '../../model/base';
import {RestQueryParams} from '../base/queryParams';
import {reservationsAPI} from '../provider/reservationsAPI';

export function changeProfileAPI(
    accountId: string,
    authToken: string,
    profile: IAccountProfileInput
): Observable<IApiSingleResponseBase<IAccountMeOutput>> {
    return reservationsAPI.put(`api/accounts/${accountId}/change_profile`, profile, new RestQueryParams(), {
        Authorization: `Bearer ${authToken}`,
    });
}
