import React from 'react';
import '../../../assets/styles/table.scss';
import {formatServerDateToString, IReservationOutput, Translation} from 'reservation-common-web';

interface TableProps {
    data: IReservationOutput[];
}
const Table: React.FC<TableProps> = ({data}) => {
    const contactData = (item: IReservationOutput) => {
        return (
            <>
                <p>
                    {item.firstName} {item.lastName}
                </p>
                <p>{item.phone}</p>
                <p>{item.email}</p>
            </>
        );
    };
    return (
        <table className="reservations-table">
            <thead>
                <tr>
                    <th>
                        <Translation text="reservationsView.table.header.contact" />
                    </th>
                    <th>
                        <Translation text="reservationsView.table.header.room" />
                    </th>
                    <th>
                        <Translation text="reservationsView.table.header.count" />
                    </th>
                    <th>
                        <Translation text="reservationsView.table.header.time" />
                    </th>
                    <th>
                        <Translation text="reservationsView.table.header.prepayment" />
                    </th>
                    <th>
                        <Translation text="reservationsView.table.header.message" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        <td>{contactData(item)}</td>
                        <td>{item.roomId}</td>
                        <td>{item.count}</td>
                        <td>
                            {formatServerDateToString(item.date)} {item.time}
                        </td>
                        <td>{item.paymentLink ? item.paymentLink : '---'}</td>
                        <td>{item.message ? item.message : '---'}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Table;
