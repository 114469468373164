import React from 'react';
import {Provider} from 'react-redux';
import store, {history, persistor} from './store';
import './App.scss';
import {PersistGate} from 'redux-persist/integration/react';
import {HistoryRouter as Router} from 'redux-first-history/rr6';
import {Routes} from 'react-router-dom';
import routes from './routes/routes';

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={history}>
                    <Routes>{routes}</Routes>
                </Router>
            </PersistGate>
        </Provider>
    );
}

export default App;
