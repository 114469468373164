import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import {initApiCall} from '../../utils/reduxHelpers';
import {IAccountMeOutput, IAccountBasicInfoOutput, DEFAULT_LOCALE, IAccountLocaleInput} from '../../model/account';
import {IChangeUserPasswordInput, UserRole} from '../../model/user';
import {IRestaurantOutput} from "../../model/restaurant";

export const NULL_ACCOUNT: IAccountMeOutput = {
    account: {
        id: '',
        userId: '',
        username: '',
        locale: DEFAULT_LOCALE,
        misc: null,
    },
    status: {
        employee: null,
        company: null,
    },
    restaurant: null,
};

export interface IAccountState {
    account: IAccountMeOutput | null;
    isLoading: boolean;
    isInitialized: boolean;
    isActionSuccessful: boolean;
    error: string | null;
    canBeDeleted: boolean;
}

export interface ISetAccountState {
    account: IAccountMeOutput;
}

export interface IUpdateAccountProfile {
    username: string;
    country: string;
    returnUrl: string;
}

interface ISetAccountInfoState {
    accountInfo: IAccountBasicInfoOutput;
}

export interface IGetFullAccount {
    accountId: string;
}

export interface IUpdateLocale {
    locale: string;
}

interface IUpdateAccountFailure {
    error: string;
}

export interface ISetAccountStateFailure {
    error: string;
}

export interface ISetShowCardRemoveModal {
    showModal: boolean;
}

export interface IUpdateUserPasswordInput {
    passwordForm: IChangeUserPasswordInput;
}

export interface ISetCanDeleteAccount {
    canBeDeleted: boolean;
}
export interface ISetAccountRestaurant {
    restaurant: IRestaurantOutput;
}

export interface IDeleteAccount {
    id: string;
}

export interface IChangeWebSettings {
    locale: string;
}

export interface IChangeUserPasswordInputSuccess {}

const initialState: IAccountState = {
    account: NULL_ACCOUNT,
    isLoading: false,
    isInitialized: false,
    isActionSuccessful: false,
    error: null,
    canBeDeleted: false,
};

const accountSlice = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {
        setAccountState: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountState>) => {
                const account: any = action.payload.account ? action.payload.account : NULL_ACCOUNT;
                return {
                    ...state,
                    account: account,
                    isInitialized: true,
                    isLoading: false,
                };
            },
            prepare(account: IAccountMeOutput) {
                return {
                    payload: {
                        account: account,
                    },
                };
            },
        },
        setIsAccountLoading: {
            reducer: (state: IAccountState, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    isLoading: action.payload,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: isLoading,
                };
            },
        },
        updateAccountSuccess: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountState>) => {
                return {
                    ...state,
                    isLoading: false,
                    showUpdateBasicInfoModal: false,
                    showChangeAvatarModal: false,
                    isActionSuccessful: true,
                    account: action.payload.account,
                };
            },
            prepare(account: IAccountMeOutput) {
                return {
                    payload: {
                        account: account,
                    },
                };
            },
        },
        updateAccountInfoSuccess: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountInfoState>) => {
                const updatedData = merge({}, state.account, {account: action.payload.accountInfo});

                return {
                    ...state,
                    isLoading: false,
                    showUpdateBasicInfoModal: false,
                    showChangeAvatarModal: false,
                    isActionSuccessful: true,
                    account: updatedData,
                };
            },
            prepare(accountInfo: IAccountBasicInfoOutput) {
                return {
                    payload: {
                        accountInfo,
                    },
                };
            },
        },
        checkCanDeleteAccount: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                };
            },
            prepare: (id: string) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        setCanDeleteAccount: {
            reducer: (state: IAccountState, action: PayloadAction<ISetCanDeleteAccount>) => {
                return {
                    ...state,
                    canBeDeleted: action.payload.canBeDeleted,
                };
            },
            prepare: (canBeDeleted: boolean) => {
                return {
                    payload: {
                        canBeDeleted,
                    },
                };
            },
        },
        deleteAccount: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (id: string) => {
                return {
                    payload: {
                        id: id,
                    },
                };
            },
        },
        setAccountStateFailure: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountStateFailure>) => {
                return {
                    ...state,
                    error: action.payload.error,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        updateAccountFailure: {
            reducer: (state: IAccountState, action: PayloadAction<IUpdateAccountFailure>) => {
                return {
                    ...state,
                    isInitialized: true,
                    error: action.payload.error,
                    isLoading: false,
                };
            },
            prepare(error: string) {
                return {
                    payload: {
                        error: error,
                    },
                };
            },
        },
        changePasswordSuccess: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: false,
                    showChangePasswordModal: false,
                };
            },
            prepare() {
                return {
                    payload: {},
                };
            },
        },
        confirmEmailChange: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(registrationToken: string, panelAccessRole: UserRole) {
                return {
                    payload: {
                        registrationToken: registrationToken,
                        panelAccessRole,
                    },
                };
            },
        },
        updateAccountProfile: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare(data: IUpdateAccountProfile) {
                return {
                    payload: data,
                };
            },
        },
        changeWebSettings: {
            reducer: (state: IAccountState) => {
                return {
                    ...state,
                    isLoading: true,
                };
            },
            prepare: (webSettings: IChangeWebSettings) => {
                return {
                    payload: {
                        locale: webSettings.locale,
                    },
                };
            },
        },
        setAccountRestaurant: {
            reducer: (state: IAccountState, action: PayloadAction<ISetAccountRestaurant>) => {
                return {
                    ...state,
                    account: {
                        ...state.account,
                        restaurant: action.payload.restaurant
                    }
                };
            },
            prepare: (restaurant: IRestaurantOutput) => {
                return {
                    payload: {
                        restaurant: restaurant
                    },
                };
            },
        },
        returnToInitialState: () => {
            return {
                ...initialState,
            };
        },
        silentRefreshDictionariesAndParameters: (state: IAccountState) => {
            return {...state};
        },
        getAccount: (state: IAccountState) => initApiCall(state),
        getFullAccount: (state: IAccountState, action: PayloadAction<IGetFullAccount>) => initApiCall(state, action),
        changeLocale: (state: IAccountState, action: PayloadAction<IUpdateLocale>) => initApiCall(state, action),
        changePassword: (state: IAccountState, action: PayloadAction<IChangeUserPasswordInput>) => initApiCall(state, action),
    },
});

export const {
    setAccountState,
    updateAccountProfile,
    updateAccountSuccess,
    updateAccountInfoSuccess,
    updateAccountFailure,
    setAccountStateFailure,
    getAccount,
    getFullAccount,
    changePassword,
    deleteAccount,
    setCanDeleteAccount,
    checkCanDeleteAccount,
    changePasswordSuccess,
    changeLocale,
    changeWebSettings,
    setIsAccountLoading,
    setAccountRestaurant,
    confirmEmailChange,
    returnToInitialState,
    silentRefreshDictionariesAndParameters,
} = accountSlice.actions;

export default accountSlice.reducer;
