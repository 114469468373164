import React from 'react';
import {PrivateRoute, Toast, UserRole, Loader, LoaderType, NotFound} from 'reservation-common-web';
import Reservations from '../Reservations';
import Navbar from '../Reservations/Navbar';
import {Routes} from 'react-router-dom';
import {Route} from 'react-router';
import Account from '../Account';
import Subscriptions from '../Subscriptions';
import RestaurantForm from '../RestaurantForm';
import {useSelector} from 'react-redux';
import {isRestaurantLoadingSelector} from '../../store/selectors/restaurantSelector';
import {isReservationsLoadingSelector} from '../../store/selectors/reservationsSelector';

interface IPanelHostProps {}

const PanelHost: React.FC<IPanelHostProps> = () => {
    const isRestaurantLoading = useSelector(isRestaurantLoadingSelector);
    const isReservationsLoading = useSelector(isReservationsLoadingSelector);

    return (
        <PrivateRoute userRole={UserRole.USER}>
            <main className="panel">
                <Navbar />
                <section className="main-container">
                    <Routes>
                        <Route path="reservations/" element={<Reservations />} key="reservations" />
                        <Route path="restaurant-form/*" element={<RestaurantForm />} key="restaurant-form" />
                        <Route path="subscriptions/" element={<Subscriptions />} key="subscriptions" />
                        <Route path="account/" element={<Account />} key="account" />
                        <Route path="*" key="not-found" element={<NotFound />} />,
                    </Routes>
                </section>
                <Toast />
                <Loader showLoader={isRestaurantLoading || isReservationsLoading} type={LoaderType.Global} />
            </main>
        </PrivateRoute>
    );
};

export default PanelHost;
