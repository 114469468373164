import {combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import {createReduxHistoryContext} from 'redux-first-history';
import {CombinedState} from '@reduxjs/toolkit';
import {
    accountSlice,
    alertSlice,
    authSlice,
    changePasswordSlice,
    CommonRootState,
    loaderSlice,
    loginSlice,
    sagaSlice,
} from 'reservation-common-web';
import reservationsSlice, {IReservationsState} from './reservationsSlice';
import restaurantSlice, {IRestaurantState} from './restaurantSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    auth: authSlice,
    account: accountSlice,
    login: loginSlice,
    changePassword: changePasswordSlice,
    alert: alertSlice,
    loader: loaderSlice,
    saga: sagaSlice,
    router: routerReducer,
    reservations: reservationsSlice,
    restaurant: restaurantSlice,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    return appReducer(state, action);
};

export type RootState = CombinedState<{
    reservations: IReservationsState;
    restaurant: IRestaurantState;
}> &
    CommonRootState;

export default rootReducer;
