import React from 'react';
import {useSelector} from 'react-redux';
import {isRestaurantInitializedSelector} from '../../../store/selectors/restaurantSelector';
import RestaurantDetailsCard from './RestaurantDetailsCard';
import OpenHoursCard from './OpenHoursCard';
import ReservationDetailsCard from './ReservationDetailsCard';
import RoomDivisionCard from './RoomDivisionCard';
import FormAppearanceCard from './FormAppearanceCard';
import FormHeaderCard from './FormHeaderCard';

interface IRestaurantFormContentProps {}

const RestaurantFormContent: React.FC<IRestaurantFormContentProps> = () => {
    const isInitialized: boolean = useSelector(isRestaurantInitializedSelector);
    const isPremium = false; // ToDo add isPremium from corresponding slice

    const renderContent = () => {
        if (!isInitialized) return <div />;

        return (
            <div className="content">
                <RestaurantDetailsCard />
                <OpenHoursCard />
                {isPremium ? <RoomDivisionCard /> : <ReservationDetailsCard />}
                <FormAppearanceCard />
                <FormHeaderCard />
            </div>
        );
    };

    return renderContent();
};

export default RestaurantFormContent;
