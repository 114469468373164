import {IModelDictionaryDatum} from '../model/dictionaryDatum';
import {DEFAULT_LOCALE, Locale} from '../model/account';

export const dictionaryTranslationName = (datum: IModelDictionaryDatum, locale?: Locale | null): string => {
    const validLocales: Locale[] = [Locale.LOCALE_EN, Locale.LOCALE_PL, Locale.LOCALE_UA],
        defaultLocale = DEFAULT_LOCALE,
        accountLocale = locale ? locale : defaultLocale,
        language = validLocales.includes(accountLocale)
            ? accountLocale.substring(0, accountLocale.indexOf('_'))
            : defaultLocale.substring(0, accountLocale.indexOf('_')),
        translatedName = (datum.name_i18n as any)[language];
    return translatedName;
};
