import {createSelector} from '@reduxjs/toolkit';
import {IModalState} from '../reducers/modalSlice';
import {CommonRootState} from '../reducers';

const selectModalSlice = (state: CommonRootState) => {
    return state.modal;
};

export const isModalOpenSelector = createSelector([selectModalSlice], (state: IModalState) => state.isOpen);
export const currentlySelectedModalTypeSelector = createSelector([selectModalSlice], (state: IModalState) => state.operationType);
