import * as Yup from 'yup';
import {FormikFieldConfig, FormikFieldTypes} from 'reservation-common-web';

const changeSettingsFormConfig = (t: (key: string) => string): FormikFieldConfig[] => {
    return [
        {
            name: 'language',
            label: t('accountView.settings.form.labels.language'),
            type: FormikFieldTypes.SELECT,
            validation: Yup.string().required(t('validation.required')),
            className: 'form-control',
            options: [
                {label: t('accountView.settings.form.labels.languageList.pl'), value: 'pl_PL'},
                {label: t('accountView.settings.form.labels.languageList.en'), value: 'en_US'},
                {label: t('accountView.settings.form.labels.languageList.uk'), value: 'uk_UA'},
            ],
        },
        {
            name: 'currency',
            label: t('accountView.settings.form.labels.currency'),
            type: FormikFieldTypes.SELECT,
            validation: Yup.string().required(t('validation.required')),
            className: 'form-control',
            options: [
                {label: t('accountView.settings.form.labels.currencyList.pln'), value: 'PLN'},
                {label: t('accountView.settings.form.labels.currencyList.eur'), value: 'EUR'},
                {label: t('accountView.settings.form.labels.currencyList.usd'), value: 'USD'},
            ],
        },
    ];
};

export default changeSettingsFormConfig;
