import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FormGenerator, IRestaurantConfigurationOutput} from 'reservation-common-web';
import CustomCard from '../../../shared/CustomCard';
import formHeaderCardFormConfig from './formHeaderCardFormConfig';
import {restaurantConfigurationSelector} from '../../../../store/selectors/restaurantSelector';
import {ISetFormHeader, setFormHeader, setIsFormHeaderValid} from '../../../../store/reducers/restaurantSlice';

const FormHeaderCard: React.FC = () => {
    const configuration: IRestaurantConfigurationOutput | null = useSelector(restaurantConfigurationSelector);
    const dispatch = useDispatch();
    const handleSubmit = (values: any) => {
        const payload: ISetFormHeader = {
            avatarId: values.avatar,
            name: values.name,
            shortDescription: values.shortDescription,
            initialDescription: values.initialDescription,
        };
        dispatch(setFormHeader(payload));
    };
    const initialValues = {
        avatar: configuration?.avatar?.fileUrls?.mediumThumb || '',
        name: configuration?.name || '',
        shortDescription: configuration?.shortDescription || '',
        initialDescription: configuration?.initialDescription || '',
    };
    const {t} = useTranslation();

    return (
        <CustomCard
            title="restaurantForm.formHeaderCard.title"
            content={
                <div className="account-form-container">
                    <FormGenerator
                        config={{
                            fields: formHeaderCardFormConfig(t),
                            initialValues,
                            onSubmit: handleSubmit,
                            formId: 'change-form-title-form',
                            formClassName: '',
                            debouncedChangeTimeout: 1000,
                        }}
                        isFormValid={(valid: boolean) => dispatch(setIsFormHeaderValid(valid))}
                    />
                </div>
            }
        />
    );
};

export default FormHeaderCard;
