import React from 'react';
import '../../../../assets/styles/navbar.scss';
import {NavLink} from 'react-router-dom';
import UsersIcon from '../../../../assets/icons/users';
import FormIcon from '../../../../assets/icons/form';
import UserIcon from '../../../../assets/icons/user';
import MenuIcon from '../../../../assets/icons/menu';
import LogoutIcon from '../../../../assets/icons/logout';
import {useDispatch} from 'react-redux';
import {logout, Translation} from 'reservation-common-web';
import {useOutsideClickHook} from './outsideClickHook';

interface INavDropdownProps {}

const NavDropdown: React.FC<INavDropdownProps> = () => {
    const dispatch = useDispatch();
    const {ref, isComponentVisible, setIsComponentVisible} = useOutsideClickHook(false);

    const renderToggle = () => {
        return (
            <div className="dropdown-toggle" onClick={() => setIsComponentVisible(!isComponentVisible)}>
                <MenuIcon />
            </div>
        );
    };

    const renderMenu = () => {
        if (!isComponentVisible) return;
        const getClasses = ({isActive}: {isActive: boolean}) => {
            return `dropdown-menu-link ${isActive ? 'active' : ''}`;
        };

        return (
            <div className="dropdown-menu">
                <ul>
                    <li className="dropdown-menu-item">
                        <NavLink to="/panel/reservations" className={getClasses}>
                            <UsersIcon />
                            <Translation text="navbar.menu.reservations" />
                        </NavLink>
                    </li>
                    <li className="dropdown-menu-item">
                        <NavLink to="/panel/restaurant-form" className={getClasses}>
                            <FormIcon />
                            <Translation text="navbar.menu.form" />
                        </NavLink>
                    </li>
                    <li className="dropdown-menu-item">
                        <NavLink to="/panel/account" className={getClasses}>
                            <UserIcon />
                            <Translation text="navbar.menu.account" />
                        </NavLink>
                    </li>
                    <li className="dropdown-menu-item">
                        <span className="dropdown-menu-divider"></span>
                    </li>
                    <li className="dropdown-menu-item">
                        <div className="dropdown-menu-link" onClick={() => dispatch(logout(true))}>
                            <LogoutIcon />
                            <Translation text="navbar.menu.logout" />
                        </div>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <div className="navbar-dropdown" ref={ref}>
            {renderToggle()}
            {renderMenu()}
        </div>
    );
};
export default NavDropdown;
