import type {SVGProps} from 'react';
import React from 'react';

const UsersIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
        <path
            d="M13.0829 11.7206C14.0719 12.2175 14.9199 13.0148 15.5388 14.0119C15.6614 14.2093 15.7227 14.308 15.7439 14.4448C15.787 14.7226 15.597 15.0641 15.3382 15.174C15.2109 15.2281 15.0677 15.2281 14.7812 15.2281M11.7242 8.79631C12.7308 8.29607 13.4225 7.25734 13.4225 6.05704C13.4225 4.85674 12.7308 3.818 11.7242 3.31777M10.3655 6.05704C10.3655 7.74539 8.99682 9.11407 7.30846 9.11407C5.62011 9.11407 4.25143 7.74539 4.25143 6.05704C4.25143 4.36868 5.62011 3 7.30846 3C8.99682 3 10.3655 4.36868 10.3655 6.05704ZM2.59331 13.8276C3.67639 12.2014 5.3855 11.1521 7.30846 11.1521C9.23142 11.1521 10.9405 12.2014 12.0236 13.8276C12.2609 14.1838 12.3795 14.362 12.3659 14.5895C12.3552 14.7667 12.2391 14.9836 12.0975 15.0906C11.9157 15.2281 11.6656 15.2281 11.1655 15.2281H3.4514C2.95128 15.2281 2.70122 15.2281 2.5194 15.0906C2.37783 14.9836 2.26169 14.7667 2.25106 14.5895C2.2374 14.362 2.35604 14.1838 2.59331 13.8276Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default UsersIcon;
