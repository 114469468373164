import React from 'react';
import '../../../assets/styles/navbar.scss';
import {Translation} from 'reservation-common-web';
import PremiumBadge from '../../shared/PremiumBadge';
import NavDropdown from './NavDropdown';

const Navbar: React.FC = () => {
    const renderLogo = () => {
        return (
            <div className="app-details">
                <img className="logo" src={require('../../../assets/images/app_logo.png')} alt="Logo" />
                <div className="app-name">
                    <p className="title">
                        <Translation text="navbar.appTitle" />
                    </p>
                    <p className="subtitle">
                        <Translation text="navbar.appSubtitle" />
                    </p>
                </div>
            </div>
        );
    };

    return (
        <nav className="navbar">
            {renderLogo()}
            <div className="navbar-right">
                <PremiumBadge />
                <NavDropdown />
            </div>
        </nav>
    );
};
export default Navbar;
