import React from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {IOpenHoursOutput, deepCloneObject, IDayOpenHoursOutput, formatTimeToHHmm} from 'reservation-common-web';
import {useDispatch, useSelector} from 'react-redux';
import {setOpenHours} from '../../../../store/reducers/restaurantSlice';
import {restaurantConfigurationOpenHoursSelector} from '../../../../store/selectors/restaurantSelector';
import CustomCard from '../../../shared/CustomCard';
import WeekDayRow from './WeekDayRow';
import {getEmptyOpenHoursOutput} from '../../../../utils/restaurantUtils';

export type IWeekDay = '_0' | '_1' | '_2' | '_3' | '_4' | '_5' | '_6';
export enum HourControlTypes {
    from = 'from',
    to = 'to',
    switch = 'switch',
}

export interface IWeekDayEvent {
    day: IWeekDay;
    type: HourControlTypes;
    value: boolean | Date;
}

const DEFAULT_HOURS_OBJ: IDayOpenHoursOutput = {
    from: '10:00',
    to: '23:00',
};

const OpenHoursCard: React.FC = () => {
    const dispatch = useDispatch();
    const openHours: IOpenHoursOutput | null = useSelector(restaurantConfigurationOpenHoursSelector);

    const daysOfWeek: IWeekDay[] = ['_0', '_1', '_2', '_3', '_4', '_5', '_6'];

    const timeValidationSchema = Yup.object({
        from: Yup.string()
            .required('Required')
            .test('is-later', 'Opening time must be earlier than closing time', function (value) {
                return value <= this.parent.to;
            }),
        to: Yup.string()
            .required('Required')
            .test('is-earlier', 'Closing time must be later than opening time', function (value) {
                return value >= this.parent.from;
            }),
    }).nullable();

    const validationSchema = Yup.object(
        daysOfWeek.reduce((acc: Record<string, Yup.ObjectSchema<{from: string; to: string} | null>>, day: string) => {
            acc[day] = timeValidationSchema as Yup.ObjectSchema<{from: string; to: string} | null>;
            return acc;
        }, {})
    );

    const initialValues: IOpenHoursOutput = openHours ? deepCloneObject(openHours) : getEmptyOpenHoursOutput();

    const handleChange = (e: IWeekDayEvent, values: IOpenHoursOutput, setFieldValue: any) => {
        let newOpenHour: any = deepCloneObject(values);
        let newValue: any = null;

        switch (e.type) {
            case HourControlTypes.switch:
                newValue = e.value ? DEFAULT_HOURS_OBJ : null;
                break;

            case HourControlTypes.from:
                newValue = {
                    from: formatTimeToHHmm(e.value as Date),
                    to: newOpenHour[e.day].to,
                };
                break;
            case HourControlTypes.to:
                newValue = {
                    from: newOpenHour[e.day].from,
                    to: formatTimeToHHmm(e.value as Date),
                };
                break;
        }

        newOpenHour[e.day] = newValue;
        console.log('handleChange after switch');
        console.log(newValue);
        console.log(newOpenHour);

        setFieldValue(e.day, newValue);
        // setValues(newValues);
        console.log('dispatch setOpenHours');
        dispatch(setOpenHours(newOpenHour));
    };

    const renderForm = () => {
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={true}
                onSubmit={(values: IOpenHoursOutput) => {
                    dispatch(setOpenHours(values));
                }}>
                {({values, setFieldValue, setValues, touched, errors, submitForm}) => {
                    return (
                        <Form className="form-container open-hours-form">
                            {/*<SetTouchedOnLoad />*/}
                            {daysOfWeek.map((day: IWeekDay) => (
                                <WeekDayRow
                                    key={`_${day}`}
                                    day={day}
                                    value={values[day]}
                                    touched={touched[day]}
                                    errors={errors[day]}
                                    onChange={(e: IWeekDayEvent) => handleChange(e, values, setFieldValue)}
                                />
                            ))}
                        </Form>
                    );
                }}
            </Formik>
        );
    };

    return <CustomCard title="restaurantForm.openHoursCard.title" content={renderForm()}></CustomCard>;
};

export default OpenHoursCard;
