export enum LanguageLocale {
    EN = 'en',
    PL = 'pl',
    UA = 'uk',
}

export enum LanguageLocaleType {
    EN = 'en_US',
    PL = 'pl_PL',
    UA = 'uk_UA',
}
