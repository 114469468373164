import {Observable} from 'rxjs';
import {RestQueryParams} from '../base/queryParams';
import {reservationsAPI} from '../provider/reservationsAPI';
import { ISetNewPasswordInput } from '../../model/user';

export function createSetNewPasswordAPI(authToken: string, payload: ISetNewPasswordInput): Observable<any> {
    return reservationsAPI.post(
        `api/users/set_new_password`,
        payload,
        new RestQueryParams(),
        {
            'X-Authentication-Token': authToken,
        }
    );
}
