import React, { ReactNode } from 'react';
import AuthHeader from "../AuthHeader";
import {UserRole} from "../../../model/user";
import {Translation} from "../../../index";

interface AuthLayoutProps {
    children: ReactNode;
    userRole: UserRole;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, userRole }) => {
    return (
        <div className="auth-layout">
            <div className="auth-form-container">
                <AuthHeader key="auth-header" userRole={userRole} />
                {children}
            </div>
            <div className="auth-content">
                <p><Translation text={'auth.content.description'}/></p>
            </div>
        </div>
    );
};

export default AuthLayout;
