import type {SVGProps} from 'react';
import React from 'react';

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.05 5.625L15.75 8.325M15.75 8.325L13.05 11.025M15.75 8.325H6.975M11.025 3.06273C10.1645 2.54584 9.16554 2.25 8.1 2.25C4.86913 2.25 2.25 4.96987 2.25 8.325C2.25 11.6801 4.86913 14.4 8.1 14.4C9.16554 14.4 10.1645 14.1042 11.025 13.5873"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default LogoutIcon;
