import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LoaderType } from "../../../model/common";
import { IRequestUserSetNewPasswordInput, UserRole } from "../../../model/user";
import { requestNewPassword } from '../../../store/reducers/authSlice';
import FormGenerator from '../../FormGenerator';
import Loader from "../../Loader";
import Translation from '../../Translation';
import resetPasswordFormConfig from './resetPasswordFormConfig';
import ChevronLeftIcon from '../../../assets/icons/chevron-left';

export interface IResetPasswordProps {
    readonly envData: any;
    readonly userRole: UserRole;
    readonly isLoading: boolean;
}

const ResetPassword: React.FC<IResetPasswordProps> = ({userRole, envData, isLoading}) => {
    const navigate = useNavigate(),
        dispatch = useDispatch();

    const loginUrl = '/auth/login';
    const {t} = useTranslation();
    const formConfig = resetPasswordFormConfig(t);
    type InitialValuesType = {
        [key: string]: any;
    };
    const initialValues: InitialValuesType = formConfig.reduce((acc, field) => {
        acc[field.name] = '';
        return acc;
    }, {} as InitialValuesType);
    const handleBack = () => {
        navigate(-1);
    };

    const resetPassword = (values: {email: string}) => {
        const baseUrl = `${envData.REACT_APP_URL}`,
            resetPasswordPayload: IRequestUserSetNewPasswordInput = {
                username: values.email,
                returnUrl: `${baseUrl}/auth/new-password`,
            };

        dispatch(requestNewPassword(resetPasswordPayload, userRole));
    }

    return (
        <>
            <Loader showLoader={isLoading} type={LoaderType.Local} />

            <div className="auth-form-header">
                {/*<div className="header-btn-container">*/}
                {/*    <button onClick={() => handleBack()}>*/}
                {/*        <ChevronLeftIcon/>*/}
                {/*        <Translation text="auth.reset_password.buttons.back" />*/}
                {/*    </button>*/}
                {/*</div>*/}

                <p className="title"><Translation text={'auth.reset_password.title'} /></p>
                <p className="description">
                    <Trans
                        i18nKey="auth.reset_password.description"
                        components={[<Link to={loginUrl} className="link-text" key="login-link" />]}
                    />
                </p>
            </div>
            <div>
            <FormGenerator
                    config={{
                        fields: formConfig,
                        initialValues,
                        onSubmit: resetPassword,
                        formId: 'auth-resetPassword-form',
                        formClassName: 'auth-form',
                        submitButtonLabel: 'auth.reset_password.buttons.sendEmail',
                        submitButtonClasses: 'btn-primary'
               }}
                />
            </div>
        </>
    );
};

export default ResetPassword;
