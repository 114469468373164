import React from 'react';

const FormIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.3 13.3875H10.35M4.95 11.025H11.7M3.6 2.25H13.05C13.7956 2.25 14.4 2.92157 14.4 3.75V14.25C14.4 15.0784 13.7956 15.75 13.05 15.75H3.6C2.85442 15.75 2.25 15.0784 2.25 14.25V3.75C2.25 2.92157 2.85442 2.25 3.6 2.25ZM8.32338 5.09306C7.85104 4.56714 7.06337 4.42567 6.47155 4.90725C5.87973 5.38884 5.79641 6.19402 6.26117 6.76359C6.72592 7.33316 8.32338 8.6625 8.32338 8.6625C8.32338 8.6625 9.92084 7.33316 10.3856 6.76359C10.8504 6.19402 10.7772 5.38377 10.1752 4.90725C9.57323 4.43074 8.79573 4.56714 8.32338 5.09306Z"
            stroke="#848A94"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default FormIcon;
