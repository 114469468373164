import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Translation, Pagination, Loader, LoaderType} from 'reservation-common-web';
import {changePagination, getReservations, IPaginationParams} from '../../store/reducers/reservationsSlice';
import {
    isReservationsLoadingSelector,
    reservationsMetadataSelector,
    reservationsPaginationSelector,
    reservationsSelector,
} from '../../store/selectors/reservationsSelector';
import Table from './Table';
import SearchPanel from './SearchPanel';

const Reservations: React.FC = () => {
    const reservations = useSelector(reservationsSelector);
    const listMetadata: {[key: string]: any} | null = useSelector(reservationsMetadataSelector);
    const isLoading = useSelector(isReservationsLoadingSelector);
    const paginationData: IPaginationParams = useSelector(reservationsPaginationSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getReservations());
    }, []);

    const changePage = (params: IPaginationParams) => {
        dispatch(changePagination(params));
    };

    const downloadReport = () => {
        console.log('Download report');
    };

    return (
        <>
            <div className="header">
                <h2 className="title">
                    <Translation text="reservationsView.title" />
                </h2>
                <div className="actions-container">
                    <button onClick={downloadReport} className="btn-back">
                        <span className="btn-icon icon-print" />
                        <Translation text={'reservationsView.btn.print'} />
                    </button>
                    <SearchPanel />
                </div>
            </div>
            <div className="content">
                {reservations?.length === 0 || reservations === null ? (
                    <p className="no-data-info">
                        <Translation text="reservationsView.table.noData" />
                    </p>
                ) : (
                    <>
                        <Table data={reservations} />
                        <Pagination
                            listMetadata={listMetadata}
                            changePage={changePage}
                            itemsPerPage={paginationData.itemsPerPage}
                            currentPage={paginationData.page}
                        />
                    </>
                )}
                <Loader showLoader={isLoading} type={LoaderType.Local} />
            </div>
        </>
    );
};

export default Reservations;
